import React from 'react';
import SpinningButton from '../../../shared/spinning_button';

import { useGlobalState } from '../../../../context/state-context'
import useFetch from 'use-http'
import { Link } from '@reach/router'



const SurveyForm = ({ status, schedule, setHistory }) => {

	const { post, response, loading, cache } = useFetch()
	const { handleError, clearError } = useGlobalState();

	return (
		<>
		{ !status.allowed  && 
			<Status reason={status.reason} />
		
		}
		<SpinningButton 
			type="click"
			handleClick={async ( e ) => {
				
				e.preventDefault();

				cache.clear();
				clearError()

				await post( '/company/surveys' )
				
				if (response.ok) {
					setHistory( response.data.history );
				} else {
					handleError( response.data );
				}

			}}
			title="Send now"
			fullWidth={false}
			disabled={!status.allowed}
			spinning={loading}
			icon="icon-send"
		/>
		</>
	)
}

const Status = ({ reason }) => {
	switch( reason ) {
		case 'no-members':
			return ( 
				<p style={{color: 'red', fontWeight: '700'}}>You have no active team members, please visit the <Link to="/team-members">team members</Link> page to add some</p>
			)
		default:
			return null
	}
}
export default SurveyForm;					