import React, {useState} from 'react';
import { Link, navigate } from '@reach/router';
import useFetch from 'use-http'

import { useAuth } from '../../../context/auth-context'
import { useGlobalState } from '../../../context/state-context'

//shared
import Errors from '../../shared/errors'
import Footer from '../../shared/footer'
import logo from '../../../assets/images/svg/HAW-logo.svg';
import SpinningButton from '../../shared/spinning_button';


const Register = (props) => {

	const { login } = useAuth();
	const { handleError, raiseError, clearError, hasFieldError, hasFieldErrorMessage } = useGlobalState();
	const { post, response, loading, cache } = useFetch('/register');

	const [form, setForm ] = useState({
		name: '',
		company: '',
		email: '',
		password: '',
		terms: false
	});


	const handleChange = ( e ) => {
		setForm({...form, [e.target.name]: e.target.value});
	}


	const isValidForm = () => {
		let errors = [];
/*
		if(form.name.length < 1){
			errors.push({ key: 'name', detail: 'Please enter your name' });
		}

		if(form.company.length < 1){
			errors.push({ key: 'company', detail: 'Please enter your company name'});
		}

		if(form.email.length < 1){
			errors.push({ key: 'email', detail: 'Please enter your email'});
		}

		if(form.password.length < 1){
			errors.push({ key: 'password', detail: 'Please enter a password'});
		}

		if( !terms ) {
			errors.push({ key: 'terms', detail: 'Please agree to our terms and conditions'});
		}

	*/

		//setFieldErrors(errors);
		if( errors.length === 0 ) {
			return true;
		} else {
			//raiseFieldErrors( errors );
			raiseError( 'Invalid', "Please check the data HERE you have entered and try again" );
			return false;
		}
	}


	const handleSubmit = async ( e ) => {

		e.preventDefault();

		clearError();

		if( isValidForm() ){

			cache.clear();

			const authResponse = await post( null, { company: {
				name: form.company,
				terms: form.terms,
				members_attributes: [{
					name: form.name,
					email: form.email,
					password: form.password
				}]
			}});

			if (response.ok) {
				await login( response.data.token );
				navigate('/');
			} else {
				//note we bypass our handle error here, so we don't redirect to login
				handleError( response.data );
			}

		} else {
			raiseError("invalid", "Please make sure you have filled out the form correctly!");
		}

	}

	return (
		<div>
			<div className="login-wrapper">
				<div className="columns is-reversed-touch">
					<div className="column">
						<div className="hero is-fullheight is-primary has-background background-main-image-container">
							<div className="text-container">
								<h1 className="title">The most effective way to measure staff satisfaction</h1>
								<h3 className="title">Using the Firo-B Model</h3>
							</div>
						</div>
					</div>
					<div className="column">
						<img className="logo" src={logo} alt="how are we" />
						<div className="login-text-container">

							<h2 className=" title is-instruct">Create new account</h2>
							<p className="instruct">Use your work email to create a new account</p>

							<Errors />

							<form onSubmit={handleSubmit}>
								<div className="field">
									<label className="label">Full Name</label>
									<div className="control">
										<input className={hasFieldError('members.name', 'input', 'input is-danger')} type="text" name="name" placeholder="enter your full name" onChange={handleChange}/>
										{hasFieldErrorMessage('members.name')}
									</div>
								</div>
								<div className="field">
									<label className="label">Company Name</label>
									<div className="control">
										<input className={hasFieldError('name', 'input', 'input is-danger')} type="text" name="company" placeholder="who do you work for?" onChange={handleChange} />
										{hasFieldErrorMessage('name')}
									</div>
								</div>
								<div className="field">
									<label className="label">Email</label>
									<div className="control">
										<input className={hasFieldError('members.email', 'input', 'input is-danger')} type="email" name="email" placeholder="enter your email" onChange={handleChange} />
										{hasFieldErrorMessage('members.email')}
									</div>
								</div>
								<div className="field">
									<label className="label">Password</label>
									<div className="control">
										<input className={hasFieldError('members.password', 'input', 'input is-danger')} type="password" name="password" placeholder="enter your password" onChange={handleChange} />
										{hasFieldErrorMessage('members.password')}
									</div>
								</div>
								<label><input type="checkbox" name="terms" className={hasFieldError('terms','', 'is-danger')} checked={form.terms} onChange={(e) => setForm({...form, terms: e.target.checked}) } /> I have read the <Link to="/terms">terms and conditions</Link></label>
								{hasFieldErrorMessage('terms')}
								<div className="control  button-container">
									<SpinningButton
										title="GET STARTED"
										fullWidth={true}
										disabled={false}
										spinning={loading}
									/>
								</div>
								<p className="action">Have an account? <Link to="/login" onClick={() => clearError()}>Login</Link></p>
							</form>

						</div>
					</div>
				</div>
			</div>
			<Footer className="embeded"/>
		</div>
	);

};
export default Register;
