import React from 'react';

const SpinningButton = ({ type, title, secondary, additionalClass, fullWidth, disabled, spinning, icon, iconAfter, handleClick }) => {

	let buttonClass = secondary ? 'button is-secondary' : 'button is-primary';

	if( fullWidth ) {
		buttonClass += ' is-fullwidth';
	}

	if( additionalClass ) {
		buttonClass += ' ' + additionalClass;
	}

	if( spinning ) {
		buttonClass += ' is-loading';
	}

	return (
		<ButtonType
			type={type}
			buttonClass={buttonClass}
			handleClick={handleClick}
			disabled={disabled}
		>
				{!spinning && (
					<>
					{icon && <span className="icon icon-send"></span>}
						<p>{title}</p>
					{iconAfter && <span className="icon icon-right"></span>}
					</>
				)}
		</ButtonType>
	)
}

const ButtonType = ({ type, buttonClass, handleClick, disabled, children }) => {
	if( type === 'click' ) {
		return (
			<button type="button" className={buttonClass} disabled={disabled} onClick={handleClick}>
				{children}
			</button>
		)
	} else {
		return (
			<button type="submit" disabled={disabled} className={buttonClass}>
				{children}
			</button>
		)
	}
}

export default SpinningButton;
