import React from 'react';
import { Location, Router } from '@reach/router'

//shared resources
import Footer from '../shared/footer'

//screens in this view
import Home from '../../components/screens/public/home'
import Login from '../../components/screens/public/login'
import Register from '../../components/screens/public/register'
import Recover from '../../components/screens/public/recover'
import Reset from '../../components/screens/public/reset'

//shared
import Faq from '../../components/screens/global/faq'
import Support from '../../components/screens/global/support'

//additional screens
import Respond from '../screens/public/respond'

const DefaultLayout = ({handleLogin, handleRegister, endpoint} ) => {

	return (
		<Location>
			{({ location }) => (
				<div className="DefaultLayout">
					<section>
						<div>
							<Router location={location}>
								<Home path="/" />
								<Login path="/login" handleLogin={handleLogin} />
								<Register path="/register" handleRegister={handleRegister} endpoint={endpoint} />
								<Recover path="/recover" />
								<Reset path="/reset/:token/:digest" />
								<Faq path="/faq"/>
								<Support path="/support"/>
								<Respond path="/respond/:token" />
							</Router>
						</div>
					</section>

					<Footer location={location} />
				</div>
			)}
		</Location>
	);
}

export default DefaultLayout;
