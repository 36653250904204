import React, { useState } from 'react';
import SkipButton from './skip'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import ReactTooltip from 'react-tooltip';
import SpinningButton from '../../../../shared/spinning_button';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../../context/state-context'

import ScoresForm from '../../settings/forms/scores'


const Scores = ({ dashboardData, setDashboardData }) => {

	const [ scores, setScores ] = useState({
		openness: dashboardData.settings.openness,
		inclusion: dashboardData.settings.inclusion,
		control: dashboardData.settings.control,
		schedule: false
	});

	const [ interacted, setInteracted ] = useState( false )
	const { post, response, loading, cache } = useFetch()
	const { handleError, raiseError, clearError, hasFieldError, hasFieldErrorMessage  } = useGlobalState();

	const handleSubmit = async ( event ) => {

		event.preventDefault();

		cache.clear()
		clearError()
		const teams = await post( '/onboard/scores', { settings: scores } )
		if (response.ok) {
			console.log( response.data, 'SCORES')
			setDashboardData( { ...dashboardData, onboard: response.data.onboard, settings: response.data.settings } )
		} else {
			handleError( response.data )
		}

	}
	
	const canSubmit = () => {
		if( scores.openness !== dashboardData.settings.openness ||  scores.inclusion !== dashboardData.settings.inclusion ||  scores.control !== dashboardData.settings.control ) {
			return true;
		}
		return false;
	}

    return (
		<div>

			<ScoresForm 
				scores={scores} 
				setScores={setScores}
			/>

			<hr />
			<div className="columns is-mobile">
				<div className="column">
					<SkipButton
						dashboardData={dashboardData}
						setDashboardData={setDashboardData}
						status={2}
						secondary={true}
						disabled={false}
					>
						Finish later
					</SkipButton>
				</div>
				<div className="column has-text-right">
					<SpinningButton
						type="click"
						handleClick={handleSubmit}
						title="Next"
						fullWidth={false}
						disabled={!canSubmit()}
						spinning={loading}
						iconAfter={true}
					/>
				</div>
			</div>


		</div>
    )
};

export default Scores
