import React from 'react';
import SpinningButton from '../../../../shared/spinning_button';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../../context/state-context'


const SkipButton = ({ status, dashboardData, setDashboardData, disabled, secondary, children }) => {

	const { post, response, loading, cache } = useFetch()
	const { handleError, clearError } = useGlobalState();

	const handleClick = async ( event ) => {

		event.preventDefault();

		cache.clear()
		clearError()


		await post( '/onboard/status', { status: status } )
		if (response.ok) {
			setDashboardData( { ...dashboardData, onboard: response.data.onboard } )
		} else {
			handleError( response.data )
		}


	}

	return (
		<>
			<SpinningButton
				type="click"
				handleClick={handleClick}
				title={children}
				fullWidth={false}
				disabled={disabled}
				spinning={loading}
				iconAfter={false}
				secondary={secondary}
			/>
		</>
	)
}

export default SkipButton