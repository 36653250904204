import React, { useState } from 'react';
import SkipButton from './skip'
import useFetch from 'use-http'
import { useGlobalState } from '../../../../../context/state-context'
import SpinningButton from '../../../../shared/spinning_button';
import ScheduleForm from '../../settings/forms/schedule'

const Schedule = ({ dashboardData, setDashboardData }) => {

	const { post, response, loading, cache } = useFetch();
	const { handleError, clearError } = useGlobalState();
	const [ schedule, setSchedule ] = useState( dashboardData.settings.schedule )

	const handleSubmit = async ( event ) => {

		event.preventDefault()

		cache.clear()
		clearError()
		await post( '/onboard/schedule', { schedule: schedule } )

		if (response.ok) {
			setDashboardData( { ...dashboardData, onboard: response.data.onboard, settings: response.data.settings } )
		} else {
			handleError( response.data )
		}
	}

	const canSubmit = () => {
		if( !schedule.auto ) return true
		if( schedule.auto && schedule.timings.start !== undefined ) return true
		return false
	}


    return (
		<div>

			<ScheduleForm
				settings={schedule}
				submitSchedule={( schedule ) => {
					setSchedule( schedule )
				}}
				nextSurvey={null}
				lastSurvey={null}

			/>


			<hr />
			<div className="columns is-mobile">
				<div className="column">
					<SkipButton
						dashboardData={dashboardData}
						setDashboardData={setDashboardData}
						status={3}
						secondary={true}
						disabled={false}
					>
						Finish later
					</SkipButton>
				</div>
				<div className="column has-text-right">
					<SpinningButton
						type="click"
						handleClick={handleSubmit}
						title="Finish"
						fullWidth={false}
						disabled={!canSubmit()}
						spinning={loading}
						iconAfter={true}
					/>
				</div>
			</div>


		</div>
    )
}

export default Schedule
