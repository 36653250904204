import React from 'react'
import { Link } from '@reach/router'
import logo from '../../../assets/images/svg/HAW-logo.svg'
import whitelogo from '../../../assets/images/svg/haw-logo-white.svg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

class Home extends React.Component {
	render() {
		return (
			<div>
				{/* <nav>
					<Link to="/login">Login</Link>{" "}
					<Link to="/register">Register</Link>
				</nav> */}
				<div className="container is-widescreen header-wrapper">
					<div className="logo-container">
						<img src={logo} className="top-logo is-hidden-touch" alt="Logo" width="180" height="25"/>
						<img src={whitelogo} className="top-logo is-hidden-desktop" alt="Logo" width="180" height="25"/>
					</div>
					<div className="button-container">
						<Link to="/login" className="is-hidden-desktop">Login</Link>
						<span className="vertical-divider is-hidden-desktop"></span>
						<Link to="/register" className="is-hidden-desktop">Signup</Link>
						<Link to="/login" className="button is-grey is-hidden-touch">Login</Link>
						<Link to="/register" className="button is-primary is-hidden-touch">Signup</Link>
					</div>
				</div>
				<div className="hero-wrapper">
					<div className="container hero-text-container">
						<div className="columns">
							<div className="column is-10-mobile is-9-tablet is-8-desktop">
								<h1 className="title">The simple and effective way to measure employee satisfaction!</h1>
							</div>
						</div>
						<div className="columns">
							<div className="column is-3-tablet is-2-desktop bottom">
								<Link to="/"><button className="button is-grey">Find out more</button></Link>
							</div>
							<div className="column is-4-tablet is-3-desktop bottom">
								<Link to="/register"><button className="button is-primary">Get Started Today <span className="icon-arrow-right"></span></button></Link>
							</div>
						</div>
					</div>
				</div>

				<div className="container is-widescreen content-container easy-to-use">
					<h2 className="section-title has-text-centered">It's easy to use <span>How are we</span></h2>
					<Carousel responsive={responsive}>
						<div className="columns">
							<div className="column">
								<div className="easy-image easy1"></div>
								<p className="easy-text has-text-centered">Set up your desired scores and add employee email addresses</p>
	            </div>
						</div>
						<div className="columns">
							<div className="column">
								<div className="easy-image easy2"></div>
								<p className="easy-text has-text-centered">Your employees receive an email with a link to your FIRO-B survey</p>
	            </div>
						</div>
						<div className="columns">
							<div className="column">
								<div className="easy-image easy3"></div>
								<p className="easy-text has-text-centered">View and analyse your results in our app</p>
          		</div>
						</div>
	        </Carousel>
					<div className="columns">
						<div className="column is-full has-text-centered">
							<Link to="/register"><button className="button is-primary is-medium">Get Started Today<span className="icon-arrow-right"></span></button></Link>
						</div>
					</div>
				</div>

        <div className="green-bg content-container">
  				<div className="container is-widescreen">
  					<div className="columns">
  						<div className="column is-12">
  							<div className="inner-content">
  								<h2 className="has-text-centered">What is <span>How are we?</span></h2>
  								<span className="large-text">How Are We is an app that was developed by the team at <a href="http://www.fizzbox.com" target="_blank">Fizzbox</a>, to measure employee satisfaction within your business.</span>

  								<p>
  									<a href="http://www.fizzbox.com" target="_blank">Fizzbox</a> is a small-medium enterprise, so we know first-hand that engaging a team can be challenging and measuring engagement levels in a meaningful way can be time-consuming. Assessing the general temperature within an office environment is tricky and when teams are working remotely, it’s even harder to see how changes and initiatives are landing. Traditional engagement surveys are often long and wordy, so people feel disinclined to work their way them and response rates suffer as a result.
  								</p>
  								<p className="has-text-centered">
  									<a href="/" className="read-more">Read more</a>
  								</p>
  							</div>
  						</div>
  					</div>
  				</div>
        </div>

				<div className="container is-widescreen content-container fizzbox">
					<div className="colunms">
						<div className="column is-12">
							<div className="inner-content">
								<h2>About <span className="fizzbox-logo"></span></h2>
								<span className="large-text"><a href="http://www.fizzbox.com" target="_blank">Fizzbox</a> is an online experiences marketplace, that helps businesses to connect their teams via virtual and at-venue teambuilding activities.</span>
								<p>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
								</p>
							</div>
						</div>
					</div>
				</div>

				<hr/>

				<div className="container is-widescreen content-container">
					<div className="columns is-centered">
						<div className="column is-12">
							<div className="inner-content">
								<div className="columns">
									<div className="column is-6-desktop">
										<h2>Measuring engagement</h2>
										<p>
											The psychological theory behind the three How Are We measures, is <strong>William Shutz’s widely used FIRO-B® theory.</strong> FIRO-B®, or <strong>Fundamental Interpersonal Relations Orientation-Behaviour</strong>, is a theory of interpersonal relations that Schutz first introduced in 1958. The theory examines the interactions of a group of localised people and is based on the belief that in all group situations, people are always looking to obtain three main interpersonal needs: <strong>openness (or affection), control and inclusion.</strong>
										</p>
									</div>
									<div className="column is-6-desktop">
										<p className="engagement-column">
											We believe that homing in on these three measures within the workplace, allows a business to:
										</p>
										<ul>
											<li><strong>Better understand employee behaviour</strong></li>
											<li><strong>Improve workplace relations</strong></li>
											<li><strong>Build and nurture trust</strong></li>
											<li><strong>Create high performing teams</strong></li>
											<li><strong>Develop more effective leaders</strong></li>
											<li><strong>Establish collaborative and successful partnerships</strong></li>
											<li><strong>Fundamentally change workplace culture for the better</strong></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="is-full-width dark">
					<div className="container is-widescreen engagement">
					<div className="columns">
						<div className="column is-full">
							<h3 className="has-text-centered">The three engagement measures</h3>
						</div>
					</div>
					<div className="columns is-mobile is-centered">
						<div className="column is-11-mobile">
							<Tabs>
								<TabList>
									<Tab>Inclusion</Tab>
									<Tab>Control</Tab>
									<Tab>Openness</Tab>
								</TabList>

								<TabPanel>
									<div className="columns">
										<div className="column is-half">
											<h4>1. A score of 10 looks like:</h4>
											<ul className="styled">
												<li>People feel included in what the business is trying to achieve, where it is heading, and how we're going to get there.</li>
												<li>People feel like they are part of something and have a clear role to play.</li>
												<li>Leaders make every effort to ensure that people are included whenever possible.</li>
											</ul>
										</div>
										<div className="column is-half">
											<h4>A score of 1 looks like:</h4>
											<ul className="styled">
												<li>Employees never know what is going on.</li>
												<li>They do not feel included in decision-making or in setting the direction</li>
												<li>Employees do not feel like they are part of the bigger picture.</li>
											</ul>
										</div>
									</div>
									<div className="columns">
										<div className="column is-full has-text-centered">
											<p className="recommended">Recommended desired score: 8/10</p>
										</div>
									</div>
								</TabPanel>
								<TabPanel>
									<div className="columns">
										<div className="column is-half">
											<h4>2. A score of 10 looks like:</h4>
											<ul className="styled">
												<li>The business has full control over what is going on</li>
												<li>There are rigid processes and procedures in place, that never bend</li>
												<li>Leaders micromanage employees and get involved in the detail</li>
											</ul>
										</div>
										<div className="column is-half">
											<h4>A score of 1 looks like:</h4>
											<ul className="styled">
												<li>The business has no controls in place</li>
												<li>There is no process or procedure</li>
												<li>Staff have total autonomy over their day to day</li>
											</ul>
										</div>
									</div>
									<div className="columns">
										<div className="column is-full has-text-centered">
											<p className="recommended">Recommended desired score: 6/10</p>
										</div>
									</div>
								</TabPanel>
								<TabPanel>
									<div className="columns">
										<div className="column is-half">
											<h4>3. A score of 10 looks like:</h4>
											<ul className="styled">
												<li>The business is open to giving and receiving feedback</li>
												<li>Leaders celebrate achievements and highlight good performance</li>
												<li>A culture of transparency, where communication is clear</li>
											</ul>
										</div>
										<div className="column is-half">
											<h4>A score of 1 looks like:</h4>
											<ul className="styled">
												<li>Leaders do not give feedback or celebrate good performance</li>
												<li>Employees do not know when they are doing well</li>
												<li>A closed culture, where nothing is shared or communicated</li>
											</ul>
										</div>
									</div>
									<div className="columns">
										<div className="column is-full has-text-centered">
											<p className="recommended">Recommended desired score: 9/10</p>
										</div>
									</div>
								</TabPanel>
							</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default Home;
