import React, { useState, useEffect } from 'react';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../../context/state-context'


const ScheduleForm = ({ settings, submitSchedule, nextSurvey, lastSurvey }) => {

	const { post, response, loading, cache } = useFetch();
	const { handleError } = useGlobalState();

	const [ schedule, setSchedule ] = useState( settings )

	useEffect(() => {
		if( settings !== null && settings ) {
			setSchedule( settings)
		}
	}, [settings]);

	useEffect(() => {
		let valid = false
		if( !schedule.auto ) {
			valid = true
		} else {
			valid = schedule.timings.frequency !== null && schedule.timings.day !== null && schedule.timings.start != null
		}
		console.log( 'VALID', valid )
		submitSchedule( schedule, valid )

	}, [schedule]);


	const [dates, setDates ] = useState([]);

	const setType = ( auto ) => {

		let timings  = null;

		if( auto ) {
			timings = schedule.timings === null ? {
				frequency: null,
				day: 1,
				start: null
			} : schedule.timings
		}
		setSchedule({...schedule, auto: auto, timings: timings})
	}


	const updateSchedule = async ( key, value ) => {

		const frequency = key === 'frequency' ? value : schedule.timings.frequency
		const day = key === 'day' ? value : schedule.timings.day

		const timings = schedule.timings

		if( key !== 'start' ) {
			const start = await getDates( frequency, day )
			timings[key] = value
			timings.start = start
		} else {
			timings[key] = value
		}

		setSchedule({...schedule, timings: timings})

	}

	const getDates = async ( frequency, day ) => {

		cache.clear();
		const nextDates = await post( '/company/settings/start-date', {
			company: {
				type: frequency,
				day: day
			}
		})
		if (response.ok) {
			setDates( nextDates );
			return nextDates.length ? nextDates[0].key : false;
		} else {
			handleError( response.data );
		}
	}

	return (
		<div className="field">
			{ !schedule && <p>LOADING </p>}
			{ schedule &&
			<>
				<SendType
					setType={(auto) => setType(auto)}
					auto={schedule.auto}
				/>

				{schedule.auto &&
					<>
					<Frequency
						schedule={schedule}
						updateSchedule={updateSchedule}
					/>

					{schedule.timings.frequency != null &&
						<>
							<Days
								schedule={schedule}
								updateSchedule={updateSchedule}
							/>
							{dates.length != 0 &&
									<div>
										<label className="label">Choose a start date</label>
										{loading ? (
											<p>Loading...</p>
										) : (
											<select className="input start" value={schedule.start} onChange={( e ) => updateSchedule( 'start', e.target.value)}>
											{dates.map((date, index) => (
												<option value={date.key} key={date.key}>{date.view}</option>
											))}
											</select>
										)}
									</div>
							}

						</>
					}
					</>
				}

				{lastSurvey != null && <p className="scheduled"><span className="icon icon-info"></span> Your last survey was sent on: {lastSurvey}</p>}
				{nextSurvey != null && <p className="scheduled"><span className="icon icon-info"></span> Your next survey is due to be sent on: {nextSurvey}</p>}
			</>
			}
		</div>
	)
}


const SendType = ({setType, auto}) => {

	return (
		<>
		<label className="label">I'd like to send my survey</label>
		<div className="field has-addons">
			<p className="control">
				<button type="button" className={!auto? 'button is-focused' : 'button'} onClick={() => setType(false)}>Manually</button>
			</p>
			<p className="control">
				<button type="button" className={auto? 'button is-focused' : 'button'} onClick={() => setType(true)}>Automatic schedule</button>
			</p>
		</div>
		</>
	)
}

const Frequency = ({schedule, updateSchedule}) => {
	return (
		<>
			<label className="label">Schedule</label>
			<div className="control">
				<select className="input timescale" value={schedule.timings.frequency != null ? schedule.timings.frequency : 0} onChange={( e ) => updateSchedule( 'frequency', e.target.value)}>
					<option value="0">Please choose a schedule</option>
					<option value="quarterly">Quarterly</option>
					<option value="monthly">Monthly</option>
					<option value="bi-anually">Bi-Anually</option>
					<option value="anually">Anually</option>
				</select>
			</div>
		</>
	)
}

const Days = ({ schedule, updateSchedule }) => {

	const setDay = async ( e, day ) => {
		e.preventDefault();
		updateSchedule( 'day', day)
	}

	return (
		<>
		<label className="label">On the following day...</label>
		<div className="field has-addons choose-day">
			<Day daykey="1" label="1st Day" day={schedule.timings.day} setDay={setDay} />
			<Day daykey="5" label="5th Day" day={schedule.timings.day} setDay={setDay} />
			<Day daykey="10" label="10th Day" day={schedule.timings.day} setDay={setDay} />
			<Day daykey="15" label="15th Day" day={schedule.timings.day} setDay={setDay} />
			<Day daykey="20" label="20th Day" day={schedule.timings.day} setDay={setDay} />
			<Day daykey="25" label="25th Day" day={schedule.timings.day} setDay={setDay} />
			<Day daykey="last" label="Last Day" day={schedule.timings.day} setDay={setDay} />
		</div>
		</>
	)
}

const Day = ({ daykey, label, day, setDay }) => {
	return (
		<p className="control">
			<button
				className={`button ${day == daykey ? ' is-focused' : null}`}
				onClick={(e) => setDay( e, daykey )}
			>
					{label}
			</button>
		</p>
	)
}


export default ScheduleForm;
