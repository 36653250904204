import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Faq = () => {
		return (
      <div className="container is-widescreen section-bg">
			  <div className="section-header">
          <span className="section-icon icon-info-blue"></span>
				  <h1 className="section-title">FAQ's</h1>
			  </div>
        <div className="section-container">
					<div className="columns">
						<div className="column is-full">
							<h3 className="section-title">What is FIRO-B?</h3>
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <p className="m-bottom">The Fundamental Interpersonal Relations Orientation–Behaviour (FIRO-B) assessment helps people understand their own behaviour and that of others in interpersonal situations. Our app provides a solution to enable you to receive scores from staff anonymously. Organisations have been using FIRO-B for a multitude of programs over the last 10 years including:
              </p>
              <ul className="styled">
                <li>Personal Growth</li>
                <li>Career Management Communication</li>
                <li>Communication</li>
                <li>Leadership</li>
                <li>Team Building</li>
                <li>Organisation Development</li>
              </ul>
            </div>
            <div className="column is-half">
              <p className="m-bottom">For more than 40 years, the FIRO-B has been used around the world to clarify human interactions in both business and personal situations. It explores three basic interpersonal needs; <strong>Inclusion, control and Openness.</strong></p>

              <p>We believe that homing in on these three measures will allow us to better understand employee behaviour, improve human relations, build trust, create high performing teams, develop more effective leaders and establish more collaborative and successful business partnerships.
              </p>
            </div>
          </div>
          <div className="columns">
						<div className="column is-full">
							<h3 className="section-title">What are the three FIRO-B measures?</h3>
            </div>
          </div>
          <div className="columns">
						<div className="column is-12">
						<Tabs>
							<TabList>
								<Tab>Inclusion</Tab>
								<Tab>Control</Tab>
								<Tab>Openness</Tab>
							</TabList>

							<TabPanel>
							<h4>1. Inclusion</h4>
							<h5>A score of 10 looks like:</h5>
							<ul className="styled">
								<li>People feel included in what the business is trying to achieve, where it is heading, and how we’re going to get there</li>
								<li>People feel like they are part of something and have a clear role to play</li>
								<li>Leaders make every effort to ensure that people are included whenever possible</li>
							</ul>
							<h5>A score of 1 looks like:</h5>
							<ul className="styled">
								<li>Employees never know what is going on</li>
								<li>They do not feel included in decision-making or in setting the direction</li>
								<li>Employees do not feel like they are part of the bigger picture</li>
							</ul>
							</TabPanel>
							<TabPanel>
							<h4>2. Control</h4>
							<h5>A score of 10 looks like:</h5>
							<ul className="styled">
								<li>The business has full control over what is going on</li>
								<li>There are rigid processes and procedures in place, that never bend</li>
								<li>Leaders micromanage employees and get involved in the detail</li>
							</ul>
							<h5>A score of 1 looks like:</h5>
							<ul className="styled">
								<li>The business has no controls in place</li>
								<li>There is no process or procedure</li>
								<li>Staff have total autonomy over their day to day</li>
							</ul>
							</TabPanel>
							<TabPanel>
							<h4>3. Openness</h4>
							<h5>A score of 10 looks like:</h5>
							<ul className="styled">
								<li>The business is open to giving and receiving feedback</li>
								<li>Leaders celebrate achievements and highlight good performance</li>
								<li>A culture of transparency, where communication is clear</li>
							</ul>
							<h5>A score of 1 looks like:</h5>
							<ul className="styled">
								<li>Leaders do not give feedback or celebrate good performance</li>
								<li>Employees do not know when they are doing well</li>
								<li>A closed culture, where nothing is shared or communicated</li>
							</ul>
							</TabPanel>
							</Tabs>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <h3 className="section-title">How does it work?</h3>
            </div>
          </div>
          <ul className="faq-stages">
            <li className="stage stage-1">
              <div className="columns is-mobile">
                <div className="column is-1">
                  <div className="stage__circle">
                    <span>1</span>
                  </div>
                </div>
                <div className="column is-11">
                  <h4 className="stage__stage-title">Add an audience</h4>
                  <p className="stage__stage-content">This is where you add the names and email addresses of who you want to participate in your survey. You can create multiple audiences, for example for different company departments or groups of staff.</p>
                </div>
              </div>
            </li>
            <li className="stage stage-2">
              <div className="columns is-mobile">
                <div className="column is-1">
                  <div className="stage__circle">
                    <span>2</span>
                  </div>
                </div>
                <div className="column is-11">
                  <h4 className="stage__stage-title">Create a survey</h4>
                  <p className="stage__stage-content">Create a survey for your audience and choose when it gets sent out - whether its weekly, monthly or ad hoc.</p>
                </div>
              </div>
            </li>
            <li className="stage stage-3">
              <div className="columns is-mobile">
                <div className="column is-1">
                  <div className="stage__circle">
                    <span>3</span>
                  </div>
                </div>
                <div className="column is-11">
                  <h4 className="stage__stage-title">Send your survey</h4>
                  <p className="stage__stage-content">If you’ve scheduled your survey, all you need to do is wait for the emails to automatically be sent. If you haven’t set a schedule, you can instantly send your survey in one click.</p>
                </div>
              </div>
            </li>
            <li className="stage stage-4">
              <div className="columns is-mobile">
                <div className="column is-1">
                  <div className="stage__circle">
                    <span>4</span>
                  </div>
                </div>
                <div className="column is-11">
                  <h4 className="stage__stage-title">View your results</h4>
                  <p className="stage__stage-content">Check back in a day or two to view the answers to your survey, and measure your current staff satisfaction.</p>
                </div>
              </div>
            </li>
          </ul>
          <div className="columns">
            <div className="column is-full">
              <h3 className="section-title">How long before I see results?</h3>
              <p>Once 80% of your audience has responded to the survey, you will be able to view your results.</p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <h3 className="section-title">Are responses anonymous?</h3>
              <p>Yes your responses to the survey are completely anonymous.</p>
            </div>
          </div>
        </div>
      </div>

		);
}

export default Faq;
