import React from 'react'
import useFetch from 'use-http'
import Slider from 'react-rangeslider'
import { useGlobalState } from '../../../../context/state-context'
import SpinningButton from '../../../shared/spinning_button';


const Summary = ({survey, token, back, setSurvey}) => {

	const { post, response, loading,cache } = useFetch( '/respond/' + survey.id + '/' + token )
	const { handleError, raiseError, clearError } = useGlobalState();


	const submit = async ( e ) => {
		
		e.preventDefault();

		if( survey.id <= 0 ) return;

		cache.clear();
		clearError()

		await post( null, {
			submission: {
				openness: survey.openness,
				inclusion: survey.inclusion,
				control: survey.control
			}
		})
    	if ( response.ok) {
			setSurvey( { ...survey, completed: response.data.completed } );
		} else {
			handleError( response.data );
		}
	}

	return (
		<div className="vote-summary">
			<h2>Thankyou for completing the survey</h2>
			<p>You're about to submit the following</p>

			<div className="columns is-mobile">
				<div className="column is-2">
					<p>Openness</p>
				</div>
				<div className="column is-9">
					<Slider
						min={1}
						max={10}
						name
						value={survey.openness}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.openness}</div>
				</div>
			</div>
			<div className="columns is-mobile">
				<div className="column is-2">
					<p>Inclusion</p>
				</div>
				<div className="column is-9">
					<Slider
						min={1}
						max={10}
						name
						value={survey.inclusion}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.inclusion}</div>
				</div>
			</div>
			<div className="columns is-mobile">
				<div className="column is-2">
					<p>Control</p>
				</div>
				<div className="column is-9">
					<Slider
						min={1}
						max={10}
						name
						value={survey.control}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.control}</div>
				</div>
			</div>

			<div>
				<button className="button is-primary back" onClick={back}>Back</button>
				<SpinningButton
					type="click"
					title="Submit"
					handleClick={submit}
					fullWidth={false}
					disabled={false}
					spinning={loading}
					iconAfter={false}
					secondary={true}
					additionalClass="next"
				/>
			</div>

		</div>
	)
}

export default Summary