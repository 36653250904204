import React from 'react';
import { Link } from '@reach/router'

const Footer = ({ location, className }) => {
	if( location !== undefined && [ '/login', '/register', '/recover'].includes(location.pathname) ) return null;
	return (
		<footer className={className}>
			<div className="container is-widescreen">
				<p><b>How are we</b> copyright &copy; {new Date().getFullYear()} <span className="divider"></span> <Link to="/faq">FAQ's</Link> <span className="divider"></span> <a href="mailto:support@howarewe.com" target="_blank">Support</a></p>
				<p className="made-by">Built by <a href="https://www.fizzbox.com" target="_blank" rel="noopener noreferrer"><span className="fizzbox-logo"></span></a></p>
			</div>
		</footer>
	)
}

export default Footer;
