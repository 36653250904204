import React, { useState } from 'react'
import Slider from 'react-rangeslider'

const Inclusion = ({survey, handleScore, next}) => {

	const [ actioned, setActioned ] = useState( true )

	const setScore = ( score ) => {
		//setActioned( true )
		handleScore( score )
	}
	
	return (
		<div>
			<p className="vote-blurb">Please submit your score for each section using the sliders below.</p>
			<div className="vote-info inclusion">
				<h1 className="vote-info--title">How would you rate <span>Inclusion</span> at work?</h1>
				<h3 className="vote-info--sub-title">A Score of <span>10</span> looks like:</h3>
				<ul>
					<li>People feel included in what the business is trying to achieve, where it is heading, and how we're going to get there</li>
					<li>People think they are part of something and have a clear role to play</li>
					<li>Leaders make every effort to ensure that people are included whenever possible</li>
				</ul>
				<h3 className="vote-info--sub-title">A Score of <span>1</span> looks like:</h3>
				<ul>
					<li>Employees never know whats going on</li>
					<li>They do not feel included in decision making or in setting the direction</li>
					<li>Employees do not feel they are part of the bigger picture</li>
				</ul>
			</div>
			<h4>Inclusion</h4>
			<div className="columns is-mobile">
				<div className="column is-11">
					<Slider
						min={1}
						max={10}
						name
						value={survey.inclusion}
						onChange={setScore}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.inclusion}</div>
				</div>
			</div>
			<button className="button is-primary next" disabled={!actioned} onClick={next}>Next</button>
		</div>
	)
}

export default Inclusion