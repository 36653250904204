import React, {useState, useEffect } from 'react';

import Members from './members'
import Scores from './scores'
import Schedule from './schedule'

const OnboardingLoader = ({ dashboardData, setDashboardData, close }) => {

	const [onboard, setOnboard] = useState(false)

	//useEffect(() => {
	//	setOnboard( onboardingData )
	//}, [onboardingData]);



	const screen = () => {
		switch( dashboardData.onboard.status ) {

			case 1:
				return ( <Scores 
							dashboardData={dashboardData}
							setDashboardData={setDashboardData} 
						/> )

			case 2:
				return ( <Schedule 
							dashboardData={dashboardData}
							setDashboardData={setDashboardData} 
						/> )

			default:
				return ( <Members 
							dashboardData={dashboardData}
							setDashboardData={setDashboardData} 
						/> )
		}

	}
	return (
		<div className="modal is-active">
			<div className="modal-background"></div>
			<div className="modal-content">
				<a onClick={() => close()} className="icon-close close-button"></a>
				<h1 className="modal-title">Welcome!</h1>
				{dashboardData.onboard.status <= 2 ? <h3 className="modal-sub-title">Let's get you started!</h3> : null}

				<div className="onboarding">
					<Progress status={dashboardData.onboard.status} />
					{screen()}
				</div>
			</div>
		</div>
	)
}

const Progress = ({ status }) => {
	return (
		<ul className="progress-steps is-clearfix">
			<li className={status == 0 ? 'active' : null}><span className="step active">1</span> <p>Members</p></li>
			<li className={status == 1 ? 'active' : null}><span className="step active">2</span> <p>Set your desired scores</p></li>
			<li className={status == 2 ? 'active' : null}><span className="step active">3</span> <p>Schedule your survey</p></li>
		</ul>
	)
}

export default OnboardingLoader;
