import React, {useState, useEffect} from 'react';
import { useParams, navigate } from '@reach/router'
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'



import Header from "../../../shared/header"
import Errors from '../../../shared/errors';
import Loader from '../../../shared/loader';
import TeamMemberEditForm from './edit';

const TeamMember = () => {

	const { handleError, clearError, renderModal, closeModal } = useGlobalState();
	const { get, del, response, loading, cache } = useFetch()

	const [ member, setMember ] = useState(false)
	const [teams, setTeams] = useState([]);

	//get the post parameters
	const params = useParams();

	//check we are just getting digits
	if( /^\d+$/.test( params.memberId ) === false ) {
		navigate('/team-members')
	}

	//load initial teams
	useEffect(() => { 
		async function loadMember() {
			cache.clear();
			await get( '/company/members/' + params.memberId );
			if (response.ok) {
				setMember(response.data.member)
				setTeams( response.data.teams)
			} else {
				handleError( response.data );
			}
		}
		loadMember() 
	}, [])


	const deleteMember = () => {
		clearError()

		renderModal(
			'delete',
			{
				title: `Delete ${member.name}?`,
				description: `Do you wish to delete ${member.name}? Please note that this employees previous scores will still count towards the your companies total.`,
				callback: async () => {

					cache.clear();
					await del( '/company/members/' + member.id )

					if (response.ok) {
						closeModal()
						navigate('/team-members')
					} else {
						handleError( response.data, 'delete' );
					}
				}
			}
		)
	}

	return (
		<div className="container is-widescreen section-bg">
			<Errors />
			<Loader isAnimating={loading} />
			{!loading && member &&
			<>
				<Header
					title={member.name}
					icon="audience"
				/>
				<div className="columns">
					<div className="column is-two-thirds">
						<TeamMemberEditForm
							member={member}
							teams={teams}
							setTeams={setTeams}
						/>
					</div>
					<div className="column is-one-third">
						<h3 className="section-title">Notes</h3>
						<a onClick={deleteMember} className="remove"><span className="icon icon-bin"></span> Delete person</a>
					</div>
				</div>
			</>
			}

		</div>
	)
}

export default TeamMember;
