import React from 'react';
import { Link } from '@reach/router'

const Timings = ({ schedule }) => {

	return (
		<div className="callout">
			<>
			{schedule.auto &&
				<div className="callout-content">
					<div class="icon-holder">
						<span className="icon icon-schedule"></span>
					</div>
					<p>Your survey is scheduled to be sent <strong>{schedule.timings.display.frequency}</strong> on the <strong>{schedule.timings.display.day}</strong> day of the month. <strong>The next scheduled send is: {schedule.next_send}</strong></p>
				</div>
			}
			{!schedule.auto &&
				<p>You have selected to send your survey manually</p>
			}
			</>
			<Link to="/settings">Change this schedule</Link>
		</div>
	)
}

export default Timings
