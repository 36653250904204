import React from 'react';
import ReactTooltip from 'react-tooltip';

const Score = ({ title, score, target }) => {
	return (
		<div className="column is-one-third">
			<ReactTooltip border={true} backgroundColor="white" borderColor="#2BBA9E" textColor="black" html={true} multiline={true} className="tooltip-styles"/>
			{title == 'Inclusion' ?
			<h3 className="section-title">{title} <span className="icon-info" data-tip="<span className='icon icon-close'></span><h2>More about Inclusion</h2> <span className='green bold'>Recommended desired score: 8/10</span> </br></br> <span className='bold'>A score of 10 looks like:</span> <ul><li>People feel included in what the business is trying to achieve, where it is heading, and how we're going to get there.</li><li>People feel like they are part of something and have a clear role to play</li><li>Leaders make every effort to ensure that people are included whenever possible</li></ul></br><span className='bold'>A score of 1 looks like:</span><ul><li>Employees never know what is going on</li><li>They do not feel included in decision-making or in setting the direction</li><li>Employees do not feel like they are part of the bigger picture</li></ul>"></span></h3>
			: null }
			{title == 'Openness' ?
			<h3 className="section-title">{title} <span className="icon-info" data-tip="<span className='icon icon-close'></span><h2>More about Openness</h2> <span className='green bold'>Recommended desired score: 9/10</span> </br></br> <span className='bold'>A score of 10 looks like:</span> <ul><li>The business is open to giving and receiving feedback</li><li>Leaders celebrate achievements and highlight good performance</li><li>A culture of transparency, where communication is clear</li></ul></br><span className='bold'>A score of 1 looks like:</span><ul><li>Leaders do not give feedback or celebrate good performance</li><li>Employees do not know when they are doing well</li><li>A closed culture, where nothing is shared or communicated</li></ul>"></span></h3>
			: null }
			{title == 'Control' ?
			<h3 className="section-title">{title} <span className="icon-info" data-tip="<span className='icon icon-close'></span><h2>More about Control</h2> <span className='green bold'>Recommended desired score: 5/10</span> </br></br> <span className='bold'>A score of 10 looks like:</span> <ul><li>The business has full control over what is going on</li><li>There are rigid processes and procedures in place, that never bend</li><li>Leaders micromanage employees and get involved in the detail</li></ul></br><span className='bold'>A score of 1 looks like:</span><ul><li>The business has no controls in place</li><li>There is no process or procedure</li><li>Staff have total autonomy over their day to day</li></ul>"></span></h3>
			: null }
			<span>Current score:</span>
			<h4><b>{score}</b>/<b>10</b></h4>
			<Bar score={score} target={target} />
		</div>
	)
}

const Bar = ({ score, target }) => {
	const scorePct = (( score / 10 ) * 100).toFixed(0);
	const targetPct = (( target / 10 ) * 100).toFixed(0);
	console.log(target);
	return (
		<div className="score-chart-wrapper">
			<div className="bar-wrapper">
				<div className="target-indicator" style={{left: `calc( ${targetPct}% - 3px`}}></div>
				<div className="bar">
					<div className="inner" style={{width: `${scorePct}%`}}></div>
				</div>
			</div>
			{target == 1 ?
			<span className="tag is-white target" style={{marginLeft: `calc( ${targetPct}% - 25px`}}>Target: {target}</span>
			: target == 9 ? <span className="tag is-white target" style={{marginLeft: `calc( ${targetPct}% - 45px`}}>Target: {target}</span>
			:	target == 10 ? <span className="tag is-white target" style={{marginLeft: `calc( ${targetPct}% - 75px`}}>Target: {target}</span>
			:
			<span className="tag is-white target" style={{marginLeft: `calc( ${targetPct}% - 35px`}}>Target: {target}</span>
			}
		</div>
	)
}

export default Score;
