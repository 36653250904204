import React from 'react';
import './styles/App.scss';

import { Provider } from 'use-http'

import { useAuth } from './context/auth-context'
import { useGlobalState } from './context/state-context'

import Modal from './components/shared/modal'

import DefaultLayout from './components/layouts/DefaultLayout';
import AuthenticatedLayout from './components/layouts/AuthenticatedLayout';

//import StateContext from './components/shared/state_context'
//import { useStateReducer } from './components/shared/state_reducer'


const App = () =>  {
	
	//const [state, dispatch] = useStateReducer();
	
	const { user, logout, headers } = useAuth();
	const { state, clearError, closeModal } = useGlobalState();

	const options = {
		cacheLife: 0,
		headers: headers(),
		onError: ({ error }) => {
			//handleError( error );
		},
		interceptors: {
			request: ({ options, url, path, route }) => {
				clearError();
				return options;
			}
		}
	}

	const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api/v1' : '/api/v1'
	
	return (
		
		<Provider url={apiUrl} options={options}>
			<div className="App">
				{ user != null ? (
					<AuthenticatedLayout
						handleLogout={() => {
							logout();
							window.location.replace( '/' );
						}}
					/>
				) : (
					<DefaultLayout />
				)}

				<Modal
					isModalOpen={state.modal}
					type={state.modal ? state.modal.type : null}
					title={state.modal ? state.modal.title : null}
					payload={state.modal ? state.modal.payload : {}}
					close={() => { closeModal() }}
				/>
			</div>
		</Provider>

	)
}


export default App;
