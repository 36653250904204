import React from 'react'
import { Link } from '@reach/router'

const Teams = ({teams}) => {
	return (
		<>
			<h3 className="section-title"><Link to="/teams">Your Teams<span className="icon icon-right"></span></Link></h3>
			{teams.length > 0 ? (
				<>
				{teams.map((team, index) => (
					<div className="columns is-mobile dashboard" key={`team-` + index}>
						<div className="column is-a-third">
							<p>{team.name}</p>
						</div>
						<div className="column is-a-third">
							<p>{team.members} People</p>
						</div>
						<div className="column is-a-third">
							<Link className="button is-primary is-pulled-right" to={`/teams/${team.id}`}>View</Link>
						</div>
					</div>
				))}
				</>
			) : (
				<div className="callout">
					No teams yet!
					<Link className="button is-primary is-pulled-right" to="/team-members">Add Team Members</Link>
				</div>
			)}
		</>
	)
}

export default Teams;
