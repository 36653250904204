import React, { lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isModalOpen, type, payload, close }) => {
	
	const importModalComponent = componentType => lazy( () => import( `../modals/${componentType}` ).catch(() => console.log( 'Component is not known' ) ) );
	const [component, setComponent] = useState(null);

	useEffect(() => {

		async function loadComponent() {
			const Component = await importModalComponent( type );
			setComponent( <Component payload={payload} close={close} /> )
		}

		if( type != null ) {
			loadComponent();
		}

		return function cleanup() { 
			setComponent(null);
		};
	}, [ type, payload, close ])

	return isModalOpen ? ReactDOM.createPortal(
		<div className="modal is-active">
			<div className="modal-background"></div>
			<div className="modal-content">
				<React.Suspense fallback='Loading component...'>
					{component}
				</React.Suspense>
			</div>
		</div>, document.body
	) : null;
}

export default Modal;