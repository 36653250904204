import React, { useState, useEffect } from 'react'
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'

import Header from "../../../shared/header"
import Loader from '../../../shared/loader';
import TeamMemberList from './list'
import TeamMemberAddForm from './add'
import TeamList from './teams'

const TeamMembers = () => {

	const { handleError, clearError } = useGlobalState();
	const { get, response, loading, cache } = useFetch('/company/members')

	const [teamMembers, setTeamMembers] = useState([]);
	const [teams, setTeams] = useState([]);

	//load initial teams
	useEffect(() => { 
		async function loadInitialTeamMembers() {
			cache.clear()
			clearError()
			await get()
			if (response.ok) {
				setTeamMembers( response.data.members )
				setTeams( response.data.teams )
			} else {
				handleError( response.data )
			}
		}
		loadInitialTeamMembers() 
	
	}, [])

	


	return (
		<div className="container is-widescreen section-bg">
			<Header
				title="Team Members"
				icon="audience"
			/>
			<div className="columns">
				<div className="column is-two-thirds">
					<div className="section-container audience">

						<h3 className="section-title">Add a new team member</h3>
						<TeamMemberAddForm
							setTeamMembers={setTeamMembers}
							teams={teams}
							setTeams={setTeams}
						/>

						<h3 className="section-title">Team members</h3>
						<Loader isAnimating={loading} />
						<TeamMemberList
							members={teamMembers}
						/>
					</div>
				</div>
				<div className="column is-one-third">
					<div className="section-container">
						{teams.length > 0 &&
							<>
							<h3 className="section-title">Teams</h3>
							<Loader isAnimating={loading} />
							{teams.length > 0 &&
								<TeamList
									teams={teams}
									setTeams={setTeams}
								/>
							}
							</>
						}
						<h3 className="section-title">Information</h3>
						<p>Your contacts will not receive any emails until you send the survey</p>
					</div>
				</div>
			</div>
		</div>
	)
};

export default TeamMembers;
