import React from 'react'
import Slider from 'react-rangeslider'

const Success = ({survey}) => {
	return (
		<div className="vote-summary">
			<div className="vote-complete"><span className="icon-complete"></span>Complete!</div>
			<h4>Thankyou for submitting your answers. Here's a summary of your survey</h4>

			<div className="columns is-mobile">
				<div className="column is-2">
					<p>Openness</p>
				</div>
				<div className="column is-9">
					<Slider
						min={1}
						max={10}
						name
						value={survey.openness}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.openness}</div>
				</div>
			</div>
			<div className="columns is-mobile">
				<div className="column is-2">
					<p>Inclusion</p>
				</div>
				<div className="column is-9">
					<Slider
						min={1}
						max={10}
						name
						value={survey.inclusion}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.inclusion}</div>
				</div>
			</div>
			<div className="columns is-mobile">
				<div className="column is-2">
					<p>Control</p>
				</div>
				<div className="column is-9">
					<Slider
						min={1}
						max={10}
						name
						value={survey.control}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.control}</div>
				</div>
			</div>
			<p>You may now close this window</p>
		</div>
	)
}

export default Success