import React, { useState } from 'react'
import Slider from 'react-rangeslider'

const Openness = ({survey, handleScore, next, back }) => {

	const [ actioned, setActioned ] = useState( true )

	const setScore = ( score ) => {
		//setActioned( true )
		handleScore( score )
	}

	return (
		<div>
			<p className="vote-blurb">Please submit your score for each section using the sliders below.</p>
			<div className="vote-info openness">
				<h1 className="vote-info--title">How would you rate <span>Openness</span> at work?</h1>
				<h3 className="vote-info--sub-title">A Score of <span>10</span> looks like:</h3>
				<ul>
					<li>The business is open to giving and receiving feedback</li>
					<li>Leaders celebrate achievements and highlight good performance</li>
					<li>A culture of transparency, where communication is clear</li>
				</ul>
				<h3 className="vote-info--sub-title">A Score of <span>1</span> looks like:</h3>
				<ul>
					<li>Leaders do not give feedback or celebrate good performance</li>
					<li>Employees do not know when they are doing well</li>
					<li>A closed culture, where nothing is shared or communicated</li>
				</ul>
			</div>
			<h4>Openness</h4>
			<div className="columns is-mobile">
				<div className="column is-11">
					<Slider
						min={1}
						max={10}
						name
						value={survey.openness}
						onChange={setScore}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.openness}</div>
				</div>
			</div>

			<button className="button is-primary back" onClick={back}>Back</button>
			<button className="button is-primary next" onClick={next} disabled={!actioned}>Next</button>

		</div>
	)
}

export default Openness