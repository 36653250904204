import React, { useState, useEffect } from 'react'


import { useGlobalState } from '../../../../context/state-context'
import useFetch from 'use-http'

//shared resources
import Header from "../../../shared/header";
import Errors from '../../../shared/errors';
import Timings from './timings';

//components for this view
import SurveyList from './survey_list'
import SurveyForm from './survey_form'

const Surveys = () => {


	const { get, response, loading } = useFetch('/company/surveys')
	const { handleError, clearError } = useGlobalState();

	const [status, setStatus] = useState( false )
	const [schedule, setSchedule] = useState( false )
	const [history, setHistory] = useState([]);

	useEffect(() => { 
		async function loadInitialData() {
			await get()
			clearError()
			if (response.ok) {
				setStatus( response.data.status )
				setSchedule(response.data.schedule);
				setHistory(response.data.history);
			} else {
				handleError( response.data );
			}
		}
		loadInitialData() 
	}, []) // componentDidMount

	

	return (

		<div className="container is-widescreen section-bg">
			<Header
				title="Surveys"
				icon="survey"
			/>

			<Errors />

			<div className="columns">
				<div className="column is-two-thirds">
					<h3 className="section-title">Your Surveys</h3>

					<SurveyList
						loading={loading}
						history={history}
					/>

					{!loading &&
						<Timings schedule={schedule} />
					}
					
					<h3 className="section-title">Schedule a manual survey</h3>

					<SurveyForm 
						status={status}
						schedule={schedule}
						setHistory={setHistory}
						
					/>
					
				</div>

				<div className="column is-one-third">
					<div className="section-container">
						<h3 className="section-title">Note</h3>

						<p>Team members will be able to score from 1-10 per sector (Openness, Inclusion & Control).</p>
					</div>
				</div>
			</div>
		</div>
	)

};

export default Surveys;
