import * as React from 'react'
import {AuthProvider} from './auth-context'
import {StateProvider} from './state-context'
function AppProviders({children}) {
	return (
		<StateProvider>
			<AuthProvider>
					{children}
			</AuthProvider>
		</StateProvider>
	)
}

export {AppProviders}