import React from 'react';
import NoItems from '../../../shared/no_items'
import Loader from '../../../shared/loader'
import { Link } from '@reach/router'

const SurveyList = ({ history, loading }) => {
	if( loading ) {
		return <Loader />
	} else {

		if( history.length === 0 ) {
			return <NoItems message="No surveys have been sent yet, please schedule one or send one now" />
		} else {
			return (
				<>
				<div className="table-container">
				  <table className="table is-fullwidth">
				    <thead className="is-hidden-touch">
				      <tr>
				        <th>Sent</th>
				        <th>Response</th>
				        <th>Inclusion</th>
								<th>Control</th>
								<th>Openness</th>
								<th></th>
				      	</tr>
				      </thead>
				      <tbody>
					  {history.map((survey, index) => {

							const formatDate = (dateString: string) => {

							var splitDate = dateString.split('/');
							var month = splitDate[1] - 1; //Javascript months are 0-11
							var date = new Date(splitDate[2], month, splitDate[0]);

							const options: Intl.DateTimeFormatOptions = { //Typescript ways of adding the type
								year: "numeric",
								month: "long",
								day: "numeric",
							};
								return new Date(date).toLocaleString('en-GB',  options);
							};

								return (
									<React.Fragment key={`survey-` + index}>
					        		<tr>
					          			<td colSpan="2" className="is-hidden-desktop">
											<p className="table-title">{formatDate(survey.date)}</p>
											<span>Response: {survey.total_responses} / {survey.total_recipients}</span>
										</td>
										<td className="is-hidden-touch"><p className="table-title">{formatDate(survey.date)}</p></td>
					          			<td className="is-hidden-touch">{survey.total_responses} / {survey.total_recipients}</td>
										<td className="is-hidden-touch">{survey.inclusion}</td>
										<td className="is-hidden-touch">{survey.control}</td>
										<td className="is-hidden-touch">{survey.openness}</td>
										<td className="has-text-right"><Link className="button is-primary" to={`/surveys/` + survey.id}><span className="icon icon-graph"></span><p className="results">Results</p></Link></td>
									</tr>
									<tr className="is-hidden-desktop">
										<td colSpan="5" className="nested">
											<table>
												<tbody>
													<tr className="is-hidden-desktop">
														<td>Inclusion: {survey.inclusion}</td>
														<td>Control: {survey.control}</td>
														<td>Openness: {survey.openness}</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</React.Fragment>
							)
					  })}

				    </tbody>
				  </table>
				</div>
				</>
			)
		}
	}
}
export default SurveyList;
