import React from 'react'
import { Link } from '@reach/router'
import SurveyStatus from './survey-status'

const TeamMemberList = ({ members }) => {

	return (
		<div className="table-container">
			<table className="table members is-fullwidth">
				<thead>
					<tr>
						<th width="30%">Name</th>
						<th className="is-hidden-touch">Email</th>
						<th className="has-text-centered is-hidden-touch">Team</th>
						<th colSpan="1" className="is-hidden-desktop"></th>
						<th className="has-text-centered">Survey</th>
						<th className="is-pulled-right">Edit</th>
					</tr>
				</thead>
				<tbody>
					{members.map((member, index) => (
						<React.Fragment key={`member-` + index}>
							<tr>
								<td colSpan="2" className="is-hidden-desktop">
									<p className="table-title">{member.name} {member.admin && <span className="icon icon-admin"></span>}</p>
								</td>
								<td className="is-hidden-touch"><p className="table-title">{member.name} {member.admin && <span className="icon icon-admin"></span>}</p></td>
								<td className="is-hidden-touch">{member.email}</td>
								<td className="is-hidden-touch has-text-centered">{member.team ? member.team.name : 'All'}</td>
								<td className="has-text-centered">
									<SurveyStatus
										member={member}
									/>
								</td>
								<td>
									<div className="icon-holder is-pulled-right">
										<Link to={`/team-members/` + member.id}><span className="icon icon-edit"></span></Link>
									</div>
								</td>
							</tr>
							<tr className="is-hidden-desktop">
								<td colSpan="5" className="nested">
									<table width="100%">
										<tbody>
											<tr className="is-hidden-desktop">
												<td width="60%"><span>{member.email}</span></td>
												<td><span>{member.team ? member.team.name : 'All'}</span></td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</React.Fragment>
					))}
				</tbody>
			</table>
		</div>
	)
};

export default TeamMemberList;
