import React from 'react'

const Support = () => {
	return (
		<div className="container is-widescreen section-bg">
			SUPPPORT
		</div>

	);
}

export default Support;
