import React from 'react';
import { useGlobalState } from '../../context/state-context'

const Errors = ({context='global'}) => {
	
	const { state, clearError } = useGlobalState();

	const renderErrors = () => {
		return (
			<div className="notification is-danger">
				<button className="delete" onClick={() => clearError()}></button>
				<b>{state.error.title}</b>
				<br />{state.error.detail}
			</div>
		);
	}
	
	return state.error && context === state.error.context ? renderErrors() : null;
}
export default Errors;