import React from 'react';
import { Router, Link } from '@reach/router'
import { useAuth } from '../../context/auth-context'

//shared resources
import logo from '../../assets/images/svg/HAW-logo.svg'
import whitelogo from '../../assets/images/svg/haw-logo-white.svg'
//import Errors from '../shared/errors'
import Footer from '../shared/footer';

//screens used in this view
import Dashboard from '../../components/screens/admin/dashboard'
import TeamMembers from '../../components/screens/admin/team-members'
import TeamMember from '../../components/screens/admin/team-members/member'
import Surveys from '../../components/screens/admin/surveys'
import Results from '../../components/screens/admin/surveys/results'
import Settings from '../../components/screens/admin/settings'

//shared screens
import Faq from '../../components/screens/global/faq'

const AuthenticatedLayout = (props) => {

  const [isActive, setisActive] = React.useState(false);
  const { user } = useAuth();

  return (
    <div className="AuthenticatedLayout">

      <section>
      <div className="top-bar">
        <div className="container is-widescreen">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <img src={logo} className="top-logo is-hidden-touch" alt="Logo" width="180" height="25"/>
                <img src={whitelogo} className="top-logo is-hidden-desktop" alt="Logo" width="180" height="25"/>
              </Link>
            </div>

            <div className={`navbar-menu is-active`}>
              <div className="navbar-start">
              <Link className="navbar-item" to="/" onClick={() => { setisActive(!isActive);}}><span className="navbar-icon icon-dashboard"></span><p className="is-hidden-touch">Dashboard</p></Link>
			  <Link className="navbar-item" to="/team-members" onClick={() => { setisActive(!isActive);}}><span className="navbar-icon icon-audience"></span><p className="is-hidden-touch">Team Members</p></Link>
              <Link className="navbar-item" to="/surveys" onClick={() => { setisActive(!isActive);}}><span className="navbar-icon icon-survey"></span><p className="is-hidden-touch">Surveys</p></Link>
              <Link className="navbar-item" to="/settings" onClick={() => { setisActive(!isActive);}}><span className="navbar-icon icon-settings"></span><p className="is-hidden-touch">Settings</p></Link>
              <a className="mob-logout is-hidden-desktop" onClick={props.handleLogout} href={() => false}>Logout</a>
              </div>

              <div className="navbar-end">
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link is-hidden-touch" href={() => false}>
                    <span className="avatar" style={{backgroundImage: `url(${user.avatar})`}}></span> Hi {user.name}
                  </a>

                  <div className="navbar-dropdown is-hidden-touch">
                    <a onClick={props.handleLogout} href={() => false}>Logout</a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>


    	<Router>
			<Dashboard path="/"/>
			<TeamMembers path="/team-members" />
			<TeamMember path="/team-members/:memberId" />
			<Surveys path="/surveys" />
			<Results path="/surveys/:surveyId" />
			<Settings path="/settings" />
			<Faq path="/faq" />
    	</Router>

      </section>

	    <Footer />

    </div>
  );
}

export default AuthenticatedLayout;
