import React from 'react';
import { ResponsiveLine } from '@nivo/line'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const AreaChart = ({ data, height, title, gradient }) => {

	return (
		<div className="chart" style={{height: height + 50, width: "100%" }}>
			<h3 className="section-title">{title}</h3>
			<ResponsiveLine
				data={data}
				height={height}
				colors={["#374354"]}
				margin={{ top: 20, right: 20, bottom: 50, left: 30 }}
				xScale={{ type: 'point' }}
				yScale={{ type: 'linear', min: 0, max: 10, stacked: true, reverse: false }}
				yFormat=" >-.2f"
				enableArea={true}
				areaOpacity={1}
				areaBaselineValue={0}
				curve="monotoneX"
        background="#F5F7FA"
				markers={[
					{
						axis: 'y',
						value: 5,
						lineStyle: { stroke: '#E3E3E3', strokeWidth: 3 },
						legend: false,
						legendOrientation: 'vertical',
					}
				]}
				layers={[
					 "grid",
					 "axes",
					"areas",
					"markers",
					"lines",
					"points",
					"slices",
					"mesh",
					"legends"
				]}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					orient: 'bottom',
					tickSize: 0,
					tickPadding: 10,
					tickRotation: 0,
					legend: false,
					legendOffset: 36,
					legendPosition: 'middle'
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 0,
					tickPadding: 20,
					tickRotation: 0,
					legend: false,
					legendOffset: -40,
					legendPosition: 'middle'
				}}
				pointSize={14}
				pointColor="#374354"
				pointBorderWidth={0}

				enableGridX={false}
				enableGridY={false}

				pointLabelYOffset={-12}
				useMesh={true}
				legends={[]}
				defs={[
					{
						id: 'inclusion',
						type: 'linearGradient',
						colors: [
							{ offset: 0, color: '#fcce32' },
							{ offset: 100, color: '#ffe17f' },
						]
					},
					{
						id: 'openness',
						type: 'linearGradient',
						colors: [
							{ offset: 0, color: '#677487' },
							{ offset: 100, color: '#a4b0c2' },
						]
					},
					{
						id: 'control',
						type: 'linearGradient',
						colors: [
							{ offset: 0, color: '#3ac3ab' },
							{ offset: 100, color: '#c4e9e4' },
						]
					},

				]}
				fill={[
					{ match: '*', id: gradient },
				]}

			/>
		</div>
	)
}
export default AreaChart;
