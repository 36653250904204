import React, {useState, useEffect} from 'react'
import 'react-rangeslider/lib/index.css'
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'

import Errors from '../../../shared/errors';
import Header from "../../../shared/header"
import Loader from '../../../shared/loader'
import ScoresForm from './forms/scores'
import ScheduleForm from './forms/schedule'
import SpinningButton from '../../../shared/spinning_button';




const Settings = () => {

	const [ scores, setScores ] = useState( false )
	const [ schedule, setSchedule ] = useState( false )

	const [ changed, setChanged ] = useState( false )
	const [ scoresChanged, setScoresChanged ] = useState( false )
	const [scheduleChanged, setScheduleChanged ] = useState( false )

	const [ loadedSettings, setLoadedSettings ] = useState(false);
	const [nextSurvey, setNextSurvey ] = useState(null);
	const [lastSurvey, setLastSurvey ] = useState(null);
	const { handleError, clearError } = useGlobalState();
	

	const { get, response, loading, cache } = useFetch('/company/settings');
	
	const {
		response: postResponse,
		loading: submitting,
		cache: postCache,
		patch: doPatch
	} = useFetch( '/company/settings' );


	useEffect(() => { loadInitialSettings() }, []); // componentDidMount

	async function loadInitialSettings() {
		cache.clear()
		clearError()
		const initialSettings = await get()
		if (response.ok) {
			parseSettings( initialSettings );
			setNextSurvey( initialSettings.schedule.next_send);
			setLastSurvey( initialSettings.schedule.last_send);
		} else {
			handleError( response.data );
		}
	}

	useEffect(() => {
		let validChanges = false
		if( scoresChanged || scheduleChanged === 'valid' ) {
			validChanges = true
		} 
		//console.log( validChanges, 'VALID CHANGES ')
		setChanged( validChanges )

	}, [schedule, scores ]);

	

			
	const handleSubmit = async ( event ) => {
		event.preventDefault();
		postCache.clear();
		const newSettings = await doPatch( null, { company: { ...scores, schedule: schedule } } )
    	if (postResponse.ok) {
			setNextSurvey( newSettings.schedule.next_send);
			setLastSurvey( newSettings.schedule.last_send);
			parseSettings( newSettings );
		} else {
			handleError( postResponse.data );
		}
	};

	const parseSettings = ( settings ) => {
	
		setLoadedSettings( settings )

		setScores({
			openness: settings.openness, 
		    inclusion: settings.inclusion, 
			control: settings.control
		})

		setSchedule({
			auto: settings.schedule.auto, 
			default: settings.schedule.default, 
			timings: settings.schedule.auto ? {
				frequency: settings.schedule.timings.frequency, 
				day: settings.schedule.timings.day, 
				start: null
			} : null
		})


	}

	/*
	const updateSettings = ( newSettings ) => {
		console.log( newSettings,'newSettings' )
		console.log( loadedSettings, 'loaded' )
		if( loadedSettings ) {
			if( newSettings.openness !== loadedSettings.openness ||  newSettings.inclusion !== loadedSettings.inclusion ||  newSettings.control !== loadedSettings.control ) {
				console.log( "CHANGES" );
				setChanged( true )
			} else if( newSettings.schedule.auto !== loadedSettings.schedule.auto || newSettings.schedule.timings.frequency !== loadedSettings.schedule.timings.frequency || parseInt( newSettings.schedule.timings.day ) !== parseInt( loadedSettings.schedule.timings.day ) || newSettings.schedule.timings.start !== loadedSettings.schedule.timings.start ) {
				console.log( "CHANGES2" );
				setChanged( true )
			} else {
				setChanged( false )
			}
		
		setSettings( newSettings )
		}
	}

	const canSubmit = () => {
		if( settings.inclusion <= 0 || settings.inclusion > 10 ) return false;
		if( settings.openness <= 0 || settings.openness > 10 ) return false;
		if( settings.control <= 0 || settings.control > 10 ) return false;
		if( settings.schedule === null ) return false;
		return true;
	}
	*/
	
	const canSubmit = () => {
		if( loadedSettings ) {
			if( scores.openness !== loadedSettings.openness ||  scores.inclusion !== loadedSettings.inclusion ||  scores.control !== loadedSettings.control ) {
				return true;
			}
		}
		return false;
	}

	
	return (
		<div className="container is-widescreen section-bg">
			<Header
				title="Settings"
				icon="settings"
			/>
			<Errors />
			{loading || !scores && <Loader /> }

			{!loading && scores && (

							<form onSubmit={handleSubmit}>
								<div className="columns is-tablet is-desktop is-widescreen is-fullhd">
									<div className="column is-12-tablet is-6-desktop is-6-widescreen is-6-fullhd">
										<div className="section-container scores">
											<h3 className="section-title">Set your desired scores</h3>
											<ScoresForm
												scores={scores}
												setScores={setScores}
											/>
										</div>
										
										<div className="control settings button-container">
												<SpinningButton
													title="Update"
													fullWidth={false}
													disabled={!canSubmit()}
													spinning={submitting}
													iconAfter={false}
													secondary={false}
												/>
											</div>
									</div>
									<div className="column is-12-tablet is-6-desktop is-6-widescreen is-6-fullhd">
										<div className="section-container schedule">

											<h3 className="section-title">Schedule your surveys</h3>
											<ScheduleForm
												settings={schedule}
												submitSchedule={( newSchedule, valid ) => {
													setSchedule( newSchedule )
													setScheduleChanged( valid )
												}}
												nextSurvey={nextSurvey}
												lastSurvey={lastSurvey}
											/>

											<div className="control settings button-container">
												<SpinningButton
													title="Update"
													fullWidth={false}
													disabled={!changed}
													spinning={submitting}
													iconAfter={false}
													secondary={false}
												/>
											</div>
										</div>
									</div>
								</div>
							</form>

			)}
		</div>
	);
};

export default Settings;
