import React, { useState } from 'react';
import { Link } from '@reach/router';
import useFetch from 'use-http'

import { useGlobalState } from '../../../context/state-context'

//shared
import Errors from '../../shared/errors'
import Footer from '../../shared/footer'
import logo from '../../../assets/images/svg/HAW-logo.svg'
import SpinningButton from '../../shared/spinning_button';

const Recover = () => {

	const { handleError, raiseError, clearError } = useGlobalState();
	const { post, response, loading, cache } = useFetch();

	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState( false )

	const handleSubmit = async ( event ) => {

		event.preventDefault();
		
		setSuccess( false )
		
		clearError()

		if( email.length <= 5 ) {
			raiseError( 'invalid', 'Please enter a valid email' );
			return false;
		}
		
		
		cache.clear()
		
		await post( 'authentication/recover', { recover: {
			email: email
		}});

    	if (response.ok) {
    		setSuccess( true )
    		setEmail('')
			console.log( 'SUCCESS' )
		} else {
			//note we bypass our handle error here, so we don't redirect to login
			handleError( response.data );
		}
	}


	return (
		<div className="login-wrapper">
			<div className="columns">
				<div className="column is-hidden-mobile">
					<div className="hero is-fullheight is-primary has-background background-main-image-container">
						<div className="text-container">
							<h1 className="title">The most effective way to measure staff satisfaction</h1>
							<h3 className="title">Using the Firo-B Model</h3>
						</div>
					</div>
				</div>
				<div className="column">
					<img className="logo" src={logo} alt="how are we" />
					<div className="login-text-container">
						<h2 className="title">Recover password</h2>
						<Errors />
						
						{success &&
							<div>Thank you, if an account exists for <b>{email}</b> we will send a link to reset your password.</div>	
						}
						
						<form onSubmit={handleSubmit}>
							<div className="field">
								<label className="label">Email</label>
								<div className="control">
									<input className="input" type="text" value={email} name="email" placeholder="enter your email" onChange={( e ) => setEmail(e.target.value)}/>
								</div>
							</div>
							<div className="control button-container">
								<SpinningButton
									title="RESET"
									fullWidth={true}
									disabled={false}
									spinning={loading}
								/>
							</div>
							<p className="action"><Link to="/login" onClick={() => clearError()}>Return to login</Link></p>
						</form>
					</div>
				</div>
			</div>
			<Footer className="embeded"/>
		</div>
	)
}
export default Recover
