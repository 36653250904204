import React, { useState } from 'react';
import SpinningButton from '../../../shared/spinning_button';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'

const Reminder = ({ id, responded, sent, reminder }) => {
	
	const { handleError, raiseError, clearError } = useGlobalState();
	const { post, response, loading, error, cache } = useFetch()
	const [ active, setActive ] = useState( reminder )
	
	const handleClick = async ( event ) => {
		
		clearError()
		cache.clear()
		
		await post( `/company/surveys/${id}/remind`, {} );

    	if (response.ok) {
    		setActive( response.data.reminder )
		} else {
			handleError( response.data );
		}
	}
	
	return (
		<div className="responses">
			<div className="totals">{responded} / {sent} Responded</div>
			<div className="remind">
				<SpinningButton 
				type="click"
				handleClick={handleClick}
				title="REMIND NON-RESPONDERS"
				fullWidth={true}
				disabled={!active}
				spinning={loading}
				icon="icon-send"
				/>
			</div>
		</div>
	)
}

export default Reminder