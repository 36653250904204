import React from 'react'
import { useAuth } from '../context/auth-context'

const StateContext = React.createContext()


function stateReducer( state, action ) {
	
	console.log( action )

	switch (action.type) {

		case 'ERROR': {
			return {...state, error: action.error }
		}
		
		case 'RESET_ERROR': {
			return {...state, error: false }
		}

		case 'MODAL': {
			return { ...state, modal: action.modal }
		}

		default: {
			throw new Error(`Unsupported action type: ${action.type}`)
		}
	
	}

}


function StateProvider(props) {
	
	const [state, dispatch] = React.useReducer(stateReducer, {
		error: false,
		modal: false
	});

	const value = React.useMemo(() => [state, dispatch], [state])
	
	return (
		<StateContext.Provider value={value} {...props} />
	)
  	
}

const modalParams = ( type, payload ) => {
	return {
		type: type,
		payload: payload
	}
}

const useGlobalState = () => {
	
	const context = React.useContext(StateContext)
	
	if (!context) {
		throw new Error(`useGlobalState must be used within a useGlobalStateProvider`)
	}

	const [state, dispatch] = context
	const { logout } = useAuth();
	
	//handle an error form a API request
	const handleError = ( error, context='global' ) => {
		
		const errorDetail = error.status === undefined ?  { title: 'Unknown error', detail: 'Sorry an unknown error has occured' } : error
		errorDetail.context = context

		console.log( context, 'contenxt')
		dispatch({ 
			type: 'ERROR', 
			error: errorDetail,
		});
		
		if( error.status === 401 ) {
    		logout()
    		window.location.replace( '/login' );
    	}
	}

	//raise an error from the application
	const raiseError = ( error, detail, context='global' ) => {
		dispatch({ type: 'ERROR', error: {title: error, detail: detail, context: context } });	
	}
	
	//clear the error
	const clearError = () => {
		console.log( 'called')
		dispatch({ type: 'RESET_ERROR' });	
	}
	
	const hasFieldError = ( field, good, bad ) => {
		return hasError( field ) ? bad : good
	}

	const hasFieldErrorMessage = ( field ) => {
		if( hasError( field ) ) {
			return <div className="input_error_message">{state.error.fields[field]}</div>
		}
	}
	
	const hasError = ( field ) => {
		return state.error && state.error.fields !== undefined && state.error.fields[field] !== undefined ? true : false
	}

	const renderModal = ( type, payload ) => {
		dispatch({ 
			type: 'MODAL',
			modal: modalParams( type, payload )
		});
	}
	
	const closeModal = () => {
		dispatch({ 
			type: 'MODAL',
			modal: false
		});
	}

  
	return {
		state,
		dispatch,
		handleError,
		raiseError,
		clearError,
		hasFieldError, 
		hasFieldErrorMessage,
		renderModal,
		closeModal
	}
}


export { StateProvider, useGlobalState }