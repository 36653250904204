import React, { useState, useEffect } from 'react'
import decode from 'jwt-decode';

const AuthContext = React.createContext();

function AuthProvider(props) {
		
	const [user, setUser] = useState(null);
	
	const login = async ( token ) => {
		if( isTokenValid( token ) ) {
			await setToken(token);
			setUser( getProfile()  );
			return true;
		} else {
			logout();
			return false;
		}	
	}

	const logout = () => {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }
	

	const loggedIn = ()  => {
        // Checks if there is a saved token and it's still valid
        return isTokenValid( getToken() ) // GEtting token from localstorage
	}
	
	useEffect(() => {
		if( loggedIn() ) {
			setUser( getProfile()  );
		}
	}, []);
	
	

	//functions to handle local storage
	
	
	const getToken = () => {
        // Retrieves the user token from localStorage
        const token = localStorage.getItem('id_token');
        return token !== 'undefined' ? token : null;
    }
    
    const setToken = async ( idToken ) => {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken);
    }
    
    const isTokenValid = (token) => {
		if( token !== null && typeof token === "string" && token.length > 0){
			return !isTokenExpired(token);
		} else {
			return false;
		}
    }

    const isTokenExpired = (token) => {
        try { 
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }
    
    const getProfile = () => {
        // Using jwt-decode npm package to decode the token
        return decode( getToken() );
    }
    
    /*
    const handleError = ( error ) => {
		console.log( 'HANDLING ERROR')
    	dispatch({ type: 'ERROR', error: error });
    	if( error.status == 401 ) {
    		logout()
    		window.location.replace( '/login' );
    	}
  
	}
	*/
    
    //utility functions
    const headers = () => {
		if( !getToken() ) return;
		const AuthStr = `Bearer ${getToken()}`;
		return { 'Authorization': AuthStr }
	}
	
	return (
		<AuthContext.Provider value={{user, login, logout, headers}} {...props} />
	)
  	
}

const useAuth = () => React.useContext(AuthContext)


export { AuthProvider, useAuth }