import React from 'react';
import Score from '../../../../shared/charts/score';
import AreaChart from '../../../../shared/charts/area';

const Results = ({settings, results}) => {

	console.log(results);

	return (
		<div className="results">
			<div className="columns scores">
				<Score title="Inclusion" score={results.inclusion.score} target={settings.inclusion} />
				<Score title="Openness" score={results.openness.score} target={settings.openness} />
				<Score title="Control" score={results.control.score} target={settings.control} />
			</div>

			{results.history.inclusion.length == 0 ?
				<div className="chart-overlay">
					<div className="chart-overlay--content">
						<h3>Coming soon!</h3>
						<p>Your score history will appear here once you receive the results of your first survey.</p>
					</div>
				</div>
			: <>
				<AreaChart
					data={[{ id: 'inclusion', data: results.history.inclusion}]}
					height={300}
					title="Inclusion"
					gradient="inclusion"
				/>

				<AreaChart
					data={[{ id: 'openness', data: results.history.openness}]}
					height={300}
					title="Openness"
					gradient="openness"
				/>

				<AreaChart
					data={[{ id: 'control', data: results.history.control}]}
					height={300}
					title="Control"
					gradient="control"
				/>
			</>
		}

		</div>
	)
}

export default Results;
