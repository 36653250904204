import React, {useState, useEffect} from 'react';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'

import Header from "../../../shared/header";
import Results from '../dashboard/panels/results'
import Teams from '../dashboard/panels/teams'
import Surveys from '../dashboard/panels/surveys'


//onboarding widget
import OnboardingLoader from './onboarding/loader';

import Loader from '../../../shared/loader'

const Dashboard = () => {

	const { handleError } = useGlobalState();
	const { get, response, loading, cache } = useFetch()
	
	
	const [results, setResults] = useState(false)
	const [teams, setTeams] = useState([])
	const [surveys, setSurveys] = useState([])
	const [settings, setSettings] = useState(false)
	const [onboardingData, setOnboardingData] = useState(false)

	const [dashboardData, setDashboardData] = useState(false)

	useEffect(() => { 
		async function loadDashboard() {
			cache.clear();
			await get('/dashboard');
			if (response.ok) {
				console.log( response.data )
				setDashboardData( response.data )
			} else {
				handleError( response.data );
			}
		}
		
		loadDashboard() 
	
	}, []); // componentDidMount

	return (
		<div className="container is-widescreen section-bg">

			<Loader isAnimating={loading} />

			{!loading && dashboardData && 
			<>
				{dashboardData.onboard && dashboardData.onboard.status < 3 && 
					<OnboardingLoader 
						dashboardData={dashboardData} 
						setDashboardData={setDashboardData}
						close={() => {
							setDashboardData( { ...dashboardData, onboard: false } )
						}}
					/> 
				}

				<Header
					title="Dashboard"
					icon="dashboard"
				/>

				<div className="section-container">
					<div className="columns">
						<div className="column is-three-fifths">
							<Results settings={dashboardData.settings} results={dashboardData.results} setDashboardData={setDashboardData} />
						</div>
						<div className="column is-two-fifths">
							<Surveys settings={dashboardData.settings} surveys={dashboardData.surveys} setDashboardData={setDashboardData} />
							<Teams teams={dashboardData.teams} setDashboardData={setDashboardData} />
						</div>
					</div>
				</div>
			</>
		}
		</div>
	)
};

export default Dashboard;
