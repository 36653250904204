import React from 'react';
import useFetch from 'use-http'
import { useParams, navigate } from '@reach/router'


//shared resources
import Header from "../../../shared/header";
import Loader from '../../../shared/loader'
import AreaChart from '../../../shared/charts/area'
import Score from '../../../shared/charts/score'
import Reminder from './reminder'

import Errors from '../../../shared/errors'


//components for this view

const Results = () => {

	//get the post parameters
	const params = useParams();
	
	//check we are just getting digits
	if( /^\d+$/.test( params.surveyId ) === false ) {
		navigate('/surveys')
	}

	//load initial results
	const { loading, data = false } = useFetch('/company/surveys/' + params.surveyId, {}, [])

	return (
		<div className="container is-widescreen section-bg results">
			
			<Loader isAnimating={loading} />
			
			<Errors />
			
			{!loading && 
			<>
			<div className="columns">
				<div className="column is-three-fifths">
					<Header
						title={`Survey on ` + data.date}
						icon="survey"
					/>
				</div>
				<div className="column is-two-fifths">
					<Reminder
						id={data.id}
						responded={data.responses.responded}
						sent={data.responses.sent}
						reminder={data.reminder}
					/>
				</div>
			</div>

			
			<div className="columns scores">
				<Score title="Inclusion" score={data.results.inclusion.score} target={data.results.inclusion.target} />
				<Score title="Openness" score={data.results.openness.score} target={data.results.openness.target} />
				<Score title="Control" score={data.results.control.score} target={data.results.control.target} />
			</div>


			<AreaChart
				data={[{ id: 'inclusion', data: data.history.inclusion}]}
				height={300}
				title="Inclusion history"
				gradient="inclusion"
			/>

			<AreaChart
				data={[{ id: 'openness', data: data.history.openness}]}
				height={300}
				title="Openness history"
				gradient="openness"
			/>

			<AreaChart
				data={[{ id: 'control', data: data.history.control}]}
				height={300}
				title="Control history"
				gradient="control"
			/>

			</>
			}
		</div>
	)
}

export default Results;
