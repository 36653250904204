import React, { useState, useEffect } from 'react';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../../context/state-context'
import SpinningButton from '../../../../shared/spinning_button';
import Errors from '../../../../shared/errors'
import SkipButton from './skip'

import TeamMemberAddForm from '../../team-members/add'

const Members =  ({ dashboardData, setDashboardData }) => {

	const { post, response, loading, cache } = useFetch()
	const { handleError, raiseError, clearError, hasFieldError, hasFieldErrorMessage  } = useGlobalState();

	const [ teamMembers, setTeamMembers ] = useState( dashboardData.onboard.members )
	const [ teams, setTeams ] = useState( dashboardData.teams )

	useEffect(() => { 
		setDashboardData( {...dashboardData, teams: teams } )
	}, [teams]);

    return (
		<div>
			<h2>This is where you add the names and email addresses of your survey participants. You also have the option to create multiple teams and allocate survey participants to those teams, for example for different company departments or groups of staff. Your survey will be sent to everyone, and you can filter the results by teams.</h2>

			<TeamMemberAddForm
				setTeamMembers={setTeamMembers}
				teams={teams}
				setTeams={setTeams}
				onBoarding={true}
			/>

			<table className="table members">
				<tbody>
					{teamMembers.map((member, index) => (
						<tr key={`member-` + index}>
							<td className="is-hidden-tablet">
								<p className="table-title">{member.name}</p>
								<span>{member.email}</span>
							</td>
							<td className="is-hidden-mobile"><p className="table-title">{member.name}</p></td>
							<td className="is-hidden-mobile">{member.email}</td>
						</tr>
					))}
				</tbody>
			</table>
			<hr />
			<div className="columns is-mobile">
				<div className="column is-one-fifth">
				<SkipButton
					dashboardData={dashboardData}
					setDashboardData={setDashboardData}
					status={1}
					secondary={true}
					disabled={false}
				>
					Finish later
				</SkipButton>
				</div>
				<div className="column has-text-right">
					<SkipButton
					dashboardData={dashboardData}
					setDashboardData={setDashboardData}
					status={1}
					secondary={false}
					disabled={dashboardData.onboard.members.length <= 0}
				>
					Next
				</SkipButton>
				</div>
			</div>
		</div>
    )
};

export default Members
