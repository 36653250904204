import React, { useState, useEffect } from 'react';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import ReactTooltip from 'react-tooltip';


const ScoresForm = ( { scores, setScores } ) => {

	

	
	return (
		<>
		<ReactTooltip border={true} backgroundColor="white" borderColor="#2BBA9E" textColor="black" html={true} multiline={true} className="tooltip-styles"/>
		<div className="columns slider-item-wrapper">
			<div className="column is-12-mobile is-3-tablet">
				<label>Inclusion <span className="icon-info" data-tip="<span className='icon icon-close'></span><h2>More about Inclusion</h2> <span className='green bold'>Recommended desired score: 8/10</span> </br></br> <span className='bold'>A score of 10 looks like:</span> <ul><li>People feel included in what the business is trying to achieve, where it is heading, and how we're going to get there.</li><li>People feel like they are part of something and have a clear role to play</li><li>Leaders make every effort to ensure that people are included whenever possible</li></ul></br><span className='bold'>A score of 1 looks like:</span><ul><li>Employees never know what is going on</li><li>They do not feel included in decision-making or in setting the direction</li><li>Employees do not feel like they are part of the bigger picture</li></ul>"></span></label>
			</div>
			<div className="column is-10-mobile is-8-tablet">
				<Slider min={1} max={10} name value={scores.inclusion} onChange={( score ) => setScores( { ...scores, inclusion: score } )}/>
			</div>
			<div className="column is-1">
				<p>{scores.inclusion}</p>
			</div>
		</div>

		<div className="columns slider-item-wrapper">
			<div className="column is-12-mobile is-3-tablet">
				<label>Openness <span className="icon-info" data-tip="<span className='icon icon-close'></span><h2>More about Openness</h2> <span className='green bold'>Recommended desired score: 9/10</span> </br></br> <span className='bold'>A score of 10 looks like:</span> <ul><li>The business is open to giving and receiving feedback</li><li>Leaders celebrate achievements and highlight good performance</li><li>A culture of transparency, where communication is clear</li></ul></br><span className='bold'>A score of 1 looks like:</span><ul><li>Leaders do not give feedback or celebrate good performance</li><li>Employees do not know when they are doing well</li><li>A closed culture, where nothing is shared or communicated</li></ul>"></span></label>
			</div>
			<div className="column is-10-mobile is-8-tablet">
				<Slider min={1} max={10} name value={scores.openness} onChange={( score ) => setScores( { ...scores, openness: score } )}/>
			</div>
			<div className="column is-1">
				<p>{scores.openness}</p>
			</div>
		</div>

		<div className="columns slider-item-wrapper">
			<div className="column is-12-mobile is-3-tablet">
				<label>Control <span className="icon-info" data-tip="<span className='icon icon-close'></span><h2>More about Control</h2> <span className='green bold'>Recommended desired score: 5/10</span> </br></br> <span className='bold'>A score of 10 looks like:</span> <ul><li>The business has full control over what is going on</li><li>There are rigid processes and procedures in place, that never bend</li><li>Leaders micromanage employees and get involved in the detail</li></ul></br><span className='bold'>A score of 1 looks like:</span><ul><li>The business has no controls in place</li><li>There is no process or procedure</li><li>Staff have total autonomy over their day to day</li></ul>"></span></label>
			</div>
			<div className="column is-10-mobile is-8-tablet">
				<Slider min={1} max={10} name value={scores.control} onChange={( score ) => setScores( { ...scores, control: score } )}/>
			</div>
			<div className="column is-1">
				<p>{scores.control}</p>
			</div>
		</div>
		</>
	);
};

export default ScoresForm;
