import React from 'react';

const Header = ({ title, icon }) => {
	return (
		<div className="section-header">
			<span className={`section-icon icon-` + icon}></span>
			<h1 className="section-title">{title}</h1>
		</div>
	)
}

export default Header;
