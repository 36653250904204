import React, { useState } from 'react';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'
import statusLoader from "../../../../assets/images/status-loader.gif"


const SurveyStatus = ({ member }) => {

	const { patch, response, loading, cache } = useFetch()
	const { handleError, clearError } = useGlobalState();

	const [status, setStatus ] = useState( member.survey )

	const setSurveyStatus = async ( e ) => {
		e.preventDefault()

		console.log( e.target.checked )
		const newStatus = e.target.checked

		cache.clear()
		clearError()


		await patch( `/company/members/${member.id}/survey_status`, { member: { survey: newStatus } } )

		if (response.ok) {
			setStatus( newStatus )
		} else {
			handleError( response.data );
		}


	}

	if( loading ) {
		return <span className="status-loader"><img src={statusLoader} alt="status loader gif"/></span>
	} else {
		return (
			<>
			<label className="check-container">
				<input
					type="checkbox"
					value={member.id}
					checked={status}
					onChange={setSurveyStatus}
				/>
				<span className="checkmark"></span>
			</label>
			</>
		)
	}
};

export default SurveyStatus;
