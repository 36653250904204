import React, { useState, useEffect } from 'react'

import 'react-rangeslider/lib/index.css'
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'

import logo from '../../../../assets/images/svg/HAW-logo.svg'
import Loader from '../../../shared/loader'
import Errors from '../../../shared/errors'

//strps
import Inclusion from './inclusion'
import Openness from './openness'
import Control from './control'
import Summary from './summary'
import Success from './success'


const Respond = ({ endpoint, token }) => {

	const { get, response, loading,cache } = useFetch('/respond/' + token )
	const { handleError, clearError } = useGlobalState();

	//what step are we on
	const [step, setStep] = useState(1);

	//this responders survey
	const [survey, setSurvey] = useState({
		id: 0,
		completed: false,
		openness: 5,
		inclusion: 5,
		control: 5
	})

	useEffect(() => { 
		
		async function loadInitialSettings() {
			cache.clear()
			clearError()
			
			await get();
			
			if (response.ok) {
				setSurvey({
					id: response.data.id,
					completed: response.data.completed,
					openness: response.data.openness,
					inclusion: response.data.inclusion,
					control: response.data.control
				})
			} else {
				handleError( response.data );
			}
		}

		loadInitialSettings() 
	
	}, [])


	const handleScore = ( score ) => {
		switch( step ) {
			case 1:
				setSurvey( { ...survey, inclusion: score });
				break
			case 2:
				setSurvey( { ...survey, openness: score });
				break;
			case 3:
				setSurvey( { ...survey, control: score });
				break;
			default:
				//humm should'nt be on this step, start again
				setStep(1);
		}
	}

	const next = ( e ) => {
		e.preventDefault();
		if( step < 5 ) {
			setStep( step +1 );
		}
	}

	const back = ( e ) => {
		e.preventDefault();
		if( step < 5) {
			setStep( step -1 );
		}
	}

	

	const renderStep = () => {
		if( survey.completed ) {
			return <Success survey={survey} />
		} else {
			switch( step ) {
				case 1:
					return <Inclusion 
						survey={survey} 
						handleScore={handleScore} 
						next={next}
						/>
				case 2:
					return <Openness 
						survey={survey} 
						handleScore={handleScore} 
						next={next}
						back={back}
						/>
				case 3:
					return <Control 
						survey={survey} 
						handleScore={handleScore} 
						next={next}
						back={back}
						/>
				case 4:
					return <Summary 
							survey={survey} 
							token={token}
							back={back}
							setSurvey={setSurvey}
							
							/>
				case 5:
					return <Success 
							survey={survey} 
							/>
			}
		}
	}

	return (
		<div>
			{loading && <Loader />}
			<>
			{!loading &&
				<div className="vote">
					<div className="vote-header">
						<img src={logo} className="top-logo" alt="Logo" width="180" height="25"/>
					</div>
					<div className="slider-item-wrapper">
						<Errors />
						{renderStep()}
					</div>
				</div>
			}
			</>
		</div>
	)
};


export default Respond;
