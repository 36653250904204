import React, { useState } from 'react';
import useFetch from 'use-http'
import { useGlobalState } from '../../../../context/state-context'
import SpinningButton from '../../../shared/spinning_button';
import Errors from '../../../shared/errors'

const TeamMemberAddForm = ({setTeamMembers, teams, setTeams }) => {
	
	const { post, response, loading, cache } = useFetch()
	const { handleError, raiseError, clearError, hasFieldError, hasFieldErrorMessage, renderModal, closeModal  } = useGlobalState();
	
	//add team form
	const [formData, setformData] = useState({
		name: '',
		email: '',
		team_id: 0
	});

	const handleChange = ( event ) => {
		event.preventDefault()
		if( event.target.name === 'team_id' && event.target.value === 'add-new-team') {
			renderModal( 'team', {
				submit: async ( teamName ) => {

					if( teamName.length > 2 ) {

						cache.clear()
						clearError()

						await post( '/company/teams', { name: teamName } )
						
						if (response.ok) {
							setTeams( response.data.teams );
							setformData( { ...formData, team_id: response.data.id})
							closeModal()
						} else {
							handleError( response.data, 'newTeam' );
						}
					} else {
						raiseError("Please enter a valid Team Name (minimum 2 characters)", 'newTeam');
					}
				}
			})
		} else {
			setformData( { ...formData, [event.target.name]: event.target.value})
		}
	}

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		cache.clear()
		clearError()
		if( formData.name.length > 2 && formData.email.length > 4 ) {

			await post( '/company/members', { member: formData } )

			if (response.ok) {
				setformData({ ...formData, name: '', email: '', team_id: 0 });
				setTeamMembers(response.data.members);
			} else {
				handleError( response.data );
			}
		} else {
			raiseError("Please enter a valid name and email address");
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Errors />
			<div className="columns">
			<div className="column is-one-quarter">
					<div className="field">
						<label className="label">Full name:</label>
						<div className="control">
							<input 
								className={hasFieldError('name','input', 'input is-danger')}
								type="text" 
								name="name" 
								value={formData.name} 
								placeholder="e.g. Jane Smith" 
								onChange={handleChange}
							/>
							{hasFieldErrorMessage('name')}
						</div>
					</div>
				</div>
				<div className="column is-one-quarter">
					<div className="field">
						<label className="label">Email:</label>
						<div className="control">
							<input 
								className={hasFieldError('email','input', 'input is-danger')}
								type="text" 
								name="email" 
								value={formData.email} 
								placeholder="e.g. jane@fizzbox.com" 
								onChange={handleChange}
							/>
							{hasFieldErrorMessage('email')}
						</div>
					</div>
				</div>

				<div className="column is-one-quarter">
					<div className="field">
						<label className="label">Team:</label>
						<div className="control">
							<select 
								className={hasFieldError('team_id','input', 'input is-danger')}
								name="team_id" 
								value={formData.team_id} 
								onChange={handleChange}
							>
								<option value="0">No team required</option>
								{teams.map((team, index) => (
									<option value={team.id} key={`option-${team.id}`}>{team.name}</option>
								))}
								<option value="add-new-team">Add new team</option>
							</select>
							{hasFieldErrorMessage('team_id')}
						</div>
					</div>
				</div>


				<div className="column is-one-quarter">
					<div className="field">
						<label></label>
						<SpinningButton
							title="Add"
							fullWidth={true}
							disabled={formData.name.length <= 2 && formData.email.length <= 4}
							spinning={loading}
							iconAfter={true}
						/>
					</div>
				</div>
			</div>
		</form>
	)
};

export default TeamMemberAddForm;
