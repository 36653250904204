import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';
import useFetch from 'use-http';

import { useAuth } from '../../../context/auth-context';
import { useGlobalState } from '../../../context/state-context';

//shared
import Errors from '../../shared/errors';
import Footer from '../../shared/footer';
import logo from '../../../assets/images/svg/HAW-logo.svg';
import SpinningButton from '../../shared/spinning_button';

const Login = () => {

	const { login } = useAuth();
	const { raiseError, clearError } = useGlobalState();
	const { post, response, loading, cache } = useFetch('/authentication');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = async ( event ) => {

		event.preventDefault();
		
		clearError()

		if( email.length <= 5 ) {
			raiseError( 'invalid', 'Please enter a valid email' );
			return false;
		}

		if( password.length <= 5 ) {
			raiseError( 'invalid', 'Please enter a valid password (min 8 characters)' );
			return false;
		}

		cache.clear();

		await post( null, { authentication: {
			email: email,
			password: password
		}});

    	if (response.ok) {
			await login( response.data.token );
			navigate('/');
		} else {
			//note we bypass our handle error here, so we don't redirect to login
			raiseError( 'Sorry please check the details and try again' );
		}
	}


	return (
		<div className="login-wrapper">
			<div className="columns">
				<div className="column is-hidden-mobile">
					<div className="hero is-fullheight is-primary has-background background-main-image-container">
						<div className="text-container">
							<h1 className="title">The most effective way to measure staff satisfaction</h1>
							<h3 className="title">Using the Firo-B Model</h3>
						</div>
					</div>
				</div>
				<div className="column">
					<img className="logo" src={logo} alt="how are we" />
					<div className="login-text-container">
						<h2 className="title">Login</h2>
						<Errors />
						<form onSubmit={handleSubmit}>
							<div className="field">
								<label className="label">Email</label>
								<div className="control">
									<input className="input" type="text" value={email} name="email" placeholder="enter your email" onChange={( e ) => setEmail(e.target.value)}/>
								</div>
							</div>
							<div className="field">
								<label className="label">Password</label>
								<div className="control">
									<input className="input" type="password" value={password} name="password" placeholder="enter your password" onChange={( e ) => setPassword(e.target.value)}/>
								</div>
							</div>
							<div className="control button-container">
								<SpinningButton
									title="LOGIN"
									fullWidth={true}
									disabled={false}
									spinning={loading}
								/>
							</div>
							<p className="action">Don't have an account? <Link to="/register" onClick={() => clearError()}>Create an account</Link></p>
							<p className="action">Forgot password? <Link to="/recover" onClick={() => clearError()}>Reset</Link></p>
						</form>


					</div>
				</div>
			</div>
			<Footer className="embeded"/>
		</div>
	)
}
export default Login
