import React, { useState } from 'react'
import Slider from 'react-rangeslider'

const Control = ({survey, handleScore, next, back}) => {

	const [ actioned, setActioned ] = useState( true )

	const setScore = ( score ) => {
		//setActioned( true )
		handleScore( score )
	}

	return (
		<div>
			<p className="vote-blurb">Please submit your score for each section using the sliders below.</p>
			<div className="vote-info control">
				<h1 className="vote-info--title">How would you rate <span>Control</span> at work?</h1>
				<h3 className="vote-info--sub-title">A Score of <span>10</span> looks like:</h3>
				<ul>
					<li>The business has full control over what is going on</li>
					<li>There are rigid processes in place, that never bend</li>
					<li>Leaders micromanage employees and get involved in detail</li>
				</ul>
				<h3 className="vote-info--sub-title">A Score of <span>1</span> looks like:</h3>
				<ul>
					<li>The business has no controls in place</li>
					<li>There is no process or procedure</li>
					<li>Staff have total autonomy over their day to day</li>
				</ul>
			</div>
			<h4>Control</h4>
			<div className="columns is-mobile">
				<div className="column is-11">
					<Slider
						min={1}
						max={10}
						name
						value={survey.control}
						onChange={setScore}
					/>
				</div>
				<div className="column is-1">
					<div className="score">{survey.control}</div>
				</div>
			</div>
			<button className="button is-primary back" onClick={back}>Back</button>
			<button className="button is-primary next" disabled={!actioned} onClick={next}>Next</button>
		</div>
	)
}

export default Control