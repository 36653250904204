import React from 'react';
import useFetch from 'use-http'

import { useGlobalState } from '../../../../context/state-context'


const TeamsList = ({ teams, setTeams }) => {
//http request to fetch
	const { patch, del, response, loading, cache } = useFetch()
	const { renderModal, closeModal, handleError, raiseError, clearError } = useGlobalState();

	const deleteTeam = ( e, team ) => {
		e.preventDefault()

		renderModal(
			'delete',
			{
				loading: loading,
				title: `Delete Team?`,
				description: `Do you wish to delete ${team.name}? ${team.members > 0 ? 'Please note: All members in this team will be removed and will no longer belong to a team.' : ''}`,
				callback: async () => {

					cache.clear();
					await del( 'company/teams/' + team.id )

					if (response.ok) {
						setTeams( response.data.teams )
						closeModal()
					} else {
						handleError( response.data, 'delete' )
					}
				}
			}
		)
	}

	const editTeam  = ( e, team ) => {
		e.preventDefault()
		renderModal( 'edit-team', {
			team: team,
			submit: async ( teamName ) => {


				if( teamName.length > 2 ) {

					cache.clear()
					clearError()

					await patch( `/company/teams/${team.id}`, { name: teamName } )
					
					if (response.ok) {
						setTeams( response.data.teams )
						closeModal()
					} else {
						handleError( response.data, 'team' );
					}
				} else {
					raiseError("Please enter a valid Team Name (minimum 2 characters)", 'team');
				}
			}
		})
	}


	return (
		<div className="table-container">
			<table className="table members is-fullwidth">
				<thead className="is-hidden-touch">
					<tr>
						<th>Team Name</th>
						<th>Count</th>
						<th>Options</th>
					</tr>
				</thead>
				<tbody>
				{teams.map((team, index) => (
					<tr key={`team-` + index}>
						<td>
							<p className="table-title">{team.name}</p>
							<span className="is-hidden-desktop">{team.members} people</span>
						</td>
						<td className="is-hidden-touch">{team.members} people</td>
						<td>
							<div className="icon-holder">
								<a href={() => false} onClick={( e ) => editTeam(e, team )}><span className="icon icon-edit"></span></a>
								<a href={() => false} onClick={( e ) => deleteTeam(e, team )}><span className="icon icon-bin"></span></a>
							</div>
						</td>
					</tr>
				))}
				</tbody>
			</table>
		</div>
	)
};

export default TeamsList;
