import React from 'react';
import { Link } from '@reach/router'
import Timings from '../../surveys/timings'

const Surveys = ({ settings, surveys }) => {

	const formatDate = (dateString: string) => {

	var splitDate = dateString.split('/');
	var month = splitDate[1] - 1; //Javascript months are 0-11
	var date = new Date(splitDate[2], month, splitDate[0]);

	const options: Intl.DateTimeFormatOptions = { //Typescript ways of adding the type
		year: "numeric",
		month: "long",
		day: "numeric",
	};
		return new Date(date).toLocaleString('en-GB',  options);
	};

	return (
		<>
			<h3 className="section-title"><Link to="/surveys">Your Surveys<span className="icon icon-right"></span></Link></h3>

			{surveys.length > 0 ? (
				<>
				{surveys.history.map((survey, index) => (
					<div className="columns is-mobile dashboard" key={`survey-` + index}>
						<div className="column is-a-third">
							<p>{formatDate(survey.date)}</p>
						</div>
						<div className="column is-a-third">
							<p>Scores: {survey.inclusion}/{survey.openness}/{survey.control}</p>
						</div>
						<div className="column is-a-third">
							<Link className="button is-primary is-pulled-right" to={`/surveys/${survey.id}`}><span className="icon icon-graph"></span> Results</Link>
						</div>
					</div>
				))}
				</>
			) : (
			<div className="columns is-mobile">
				<div className="column">
					<Timings schedule={settings.schedule} />
				</div>
			</div>
			)}
		</>
	)
}

export default Surveys;
